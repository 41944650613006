
import React, { Component } from 'react';
// var _ = require('lodash');

function rand(val) {
    return Math.floor(Math.random() * Math.floor(val))
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class Simu2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tickSimulation: 1000,
            tickDone: 0,
            life: 0
        }
        this.xVal = []
        this.yVal = []
        this.lenTotal = 20
        for (var i = 0; i < this.lenTotal; i++) {
            this.xVal.push(i)
            this.yVal.push(i)
        }
        this.plat = null
        this.blob = {}
        this.moving = false
        this.nextPosBlob = []
        this.fast = 1000
    }

    componentDidMount() {
        this.createSimu()
    }

    createNewBlobs(val) {
        if (this.state.life >= 20) console.log('Too much fish in this tank !')
        else {
            var lifeNbr = this.state.life
            if (lifeNbr + val > 20) val = 20 - lifeNbr
            this.setState({ life: this.state.life + val })
            for (var x = 0; x < val; x++) {
                this.createNewBlob()
            }
        }
    }


    createNewBlob() {
        var tok = this.generateToken(8)
        var pos = this.simuPosi()
        this.blob[tok] = {}
        this.blob[tok]["pos"] = [pos[0], pos[1]]
        this.blob[tok]["facing"] = this.randFacing()
        this.blob[tok]["fish"] = this.randFish()
        // this.blob[tok]['rand5'] = rand(5)
        // this.blob[tok]['rand3'] = rand(3)
        // this.blob[tok]['rand2'] = rand(2)
        if (!this.moving) {
            setTimeout(async function () {
                for (var x = 0; x < 1000; x++) {
                    await this.moveBlobs()
                }
            }.bind(this), 1000)
        }
        this.moving = true
        this.setState({ r: 1 })
    }

    randFish() {
        var randFish = ["/nemo.png", "spade.png", "balou.png", 'turtle.png', "poulpy.png"]
        return randFish[rand(randFish.length)]
        // return _.sample(randFish)
    }

    changeFacing(old, border, blob) {
        var new_f = old
        var possible = ['N', 'S', 'E', 'W', 'NE', 'NW', "SE", "SW"]
        if (border) {
            if (old === "N") {
                possible = ["S", "SE", "SW", "E", "W"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand5']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "S") {
                possible = ["N", "NE", "NW", "E", "W"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand5']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "E") {
                possible = ["N", "NW", "W", "SW", "S"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand5']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "W") {
                possible = ["N", "NE", "E", "SE", "S"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand5']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "NE") {
                possible = ["S", "W", "SW"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand3']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "NW") {
                possible = ["S", "E", "SE"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand3']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "SE") {
                possible = ["N", "W", "NW"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand3']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "SW") {
                possible = ["N", "E", "NE"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand3']]
                new_f = possible[rand(possible.length)]
            }
        }
        else {
            if (old === "N") {
                possible = ["NE", "NW"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "S") {
                possible = ["SE", "SW"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "E") {
                possible = ["NE", "SE"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "W") {
                possible = ["NW", "SW"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "NE") {
                possible = ["N", "E"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "NW") {
                possible = ["N", "W"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "SE") {
                possible = ["S", "E"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
            if (old === "SW") {
                possible = ["S", "W"]
                // new_f = _.sample(possible)
                // new_f = possible[blob['rand2']]
                new_f = possible[rand(possible.length)]
            }
        }
        return new_f
    }

    async moveBlobs() {
        var back = JSON.parse(JSON.stringify(this.blob))
        var keys = Object.keys(back)
        this.nextPosBlob = []
        keys.forEach(ele => {
            if (rand(10) === 1) back[ele]['facing'] = this.changeFacing(back[ele]["facing"], false, back[ele])
            back[ele] = this.moveBlob(back[ele])
            this.nextPosBlob.push(back[ele]['pos'][0] + '-' + back[ele]['pos'][1])
            this.nextPosBlob.push(back[ele]['next'][0] + '-' + back[ele]['next'][1])
            back[ele]['pos'] = back[ele]['next']
        })
        this.blob = back
        this.setState({ tickDone: this.state.tickDone + 1 })
        // this.setState({ r: 1 })
        await timeout(this.fast)
        // setTimeout(function () {
        //     this.moveBlobs()
        // }.bind(this), this.fast)
    }

    moveBlob(b) {
        var timeoutA = this.fast
        b["next"] = [b['pos'][0], b['pos'][1]]
        if (b['facing'] === "N") {
            if (b["next"][1] === 0) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else b["next"][1] = b["next"][1] - 1
        }
        else if (b['facing'] === "S") {
            if (b["next"][1] === this.lenTotal - 1) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else b["next"][1] = b["next"][1] + 1
        }
        else if (b['facing'] === "E") {
            if (b["next"][0] === this.lenTotal - 1) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else b["next"][0] = b["next"][0] + 1
        }
        else if (b['facing'] === "W") {
            if (b["next"][0] === 0) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else b["next"][0] = b["next"][0] - 1
        }
        else if (b['facing'] === "NE") {
            if (b["next"][1] === 0 && b["next"][0] < this.lenTotal - 1) {
                b["facing"] = "E"
                timeoutA = 0
            }
            else if (b["next"][1] > 0 && b["next"][0] === this.lenTotal - 1) {
                b["facing"] = "N"
                timeoutA = 0
            }
            else if (b["next"][1] === 0 && b["next"][0] === this.lenTotal - 1) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else {
                b["next"][1] = b["next"][1] - 1
                b["next"][0] = b["next"][0] + 1
            }
        }
        else if (b['facing'] === "NW") {
            if (b["next"][1] === 0 && b["next"][0] > 0) {
                b["facing"] = "W"
                timeoutA = 0
            }
            else if (b["next"][1] > 0 && b["next"][0] === 0) {
                b["facing"] = "N"
                timeoutA = 0
            }
            else if (b["next"][1] === 0 && b["next"][0] === 0) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else {
                b["next"][1] = b["next"][1] - 1
                b["next"][0] = b["next"][0] - 1
            }
        }
        else if (b['facing'] === "SE") {
            if (b["next"][1] === this.lenTotal - 1 && b["next"][0] < this.lenTotal - 1) {
                b["facing"] = "E"
                timeoutA = 0
            }
            else if (b["next"][1] < this.lenTotal - 1 && b["next"][0] === this.lenTotal - 1) {
                b["facing"] = "S"
                timeoutA = 0
            }
            else if (b["next"][1] === this.lenTotal - 1 && b["next"][0] === this.lenTotal - 1) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else {
                b["next"][1] = b["next"][1] + 1
                b["next"][0] = b["next"][0] + 1
            }
        }
        else if (b['facing'] === "SW") {
            if (b["next"][1] === this.lenTotal - 1 && b["next"][0] > 0) {
                b["facing"] = "W"
                timeoutA = 0
            }
            else if (b["next"][1] < this.lenTotal - 1 && b["next"][0] === 0) {
                b["facing"] = "S"
                timeoutA = 0
            }
            else if (b["next"][1] === this.lenTotal - 1 && b["next"][0] === 0) {
                b["facing"] = this.changeFacing(b["facing"], true, b)
                timeoutA = 0
            }
            else {
                b["next"][1] = b["next"][1] + 1
                b["next"][0] = b["next"][0] - 1
            }
        }
        if (this.nextPosBlob.indexOf(b['next'][0] + '-' + b['next'][1]) !== -1) {
            b["next"] = b["pos"]
            b["facing"] = this.changeFacing(b["facing"], false, b)
            return this.moveBlob(b)
        }
        else {
            // b["pos"] = b["next"]
            if (timeoutA === 0) return this.moveBlob(b)
            else return b
        }
    }

    createSimu() {
        this.plat = {}
        this.xVal.forEach(x => {
            this.yVal.forEach(y => {
                var val = x + "-" + y
                this.plat[val] = {
                    'type': 'solid'
                }
            })
        })
        this.setState({ r: 1 })
    }

    randFacing() {
        var facing = ['N', 'S', 'E', 'W', 'NE', 'NW', "SE", "SW"]
        return facing[Math.floor(Math.random() * Math.floor(facing.length - 1))]
        // return 'N'
    }

    generateToken(length) {
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];
        for (var i = 0; i < length; i++) {
            var j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        var tok = b.join("")
        return tok;
    }

    simuPosi() {
        var len = this.lenTotal
        return [Math.floor(Math.random() * Math.floor(len - 1)), Math.floor(Math.random() * Math.floor(len))]
    }

    render() {
        var size = 20
        // var color = ["rgba(255, 255, 255)", "rgb(64, 212, 51)", "rgb(138, 245, 66)", "rgb(185, 212, 51)", "rgb(212, 177, 51)", "rgb(212, 129, 51)", "rgb(212, 86, 51)"]
        // var colorMap = {
        //     'forest': "#79c389",
        //     "water": "#8aa6c5",
        //     'plains': "#ccec9e",
        //     "moutains": "#929292"
        // }
        var rotateVal = {
            "W": "rotate(0deg)",
            "NW": "rotate(45deg)",
            "N": "rotate(90deg)",
            "NE": "rotate(135deg) scaleY(-1)",
            "E": "rotate(180deg) scaleY(-1)",
            "SE": "rotate(225deg) scaleY(-1)",
            "S": "rotate(270deg) scaleY(-1)",
            "SW": "rotate(315deg)",
        }
        return (
            <div style={{ width: "100%", border: "0px solid", position: "relative" }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="btn-top" onClick={() => this.createNewBlobs(1)} style={this.state.life >= 20 ? { textDecoration: "line-through" } : {}}>
                        Add life
                    </div>
                    <div className="btn-top" onClick={() => this.createNewBlobs(5)} style={this.state.life >= 20 ? { textDecoration: "line-through" } : {}}>
                        Add 5 life
                    </div>
                    <div className="btn-top" onClick={() => this.createNewBlobs(20)} style={this.state.life >= 20 ? { textDecoration: "line-through" } : {}}>
                        Add 20 life
                    </div>
                </div>
                {/* {Object.keys(rotateVal).map((k, ki) =>
                    <img key={ki + "sdjkf"} src="/nemo.png" style={{ height: 14, width: 'auto', transform: rotateVal[k] }} />
                )} */}
                <div className="map-params" style={{ width: this.xVal.length * size + 10, height: this.yVal.length * size + 10, background: "url(seabed.png)", backgroundPosition: "center", backgroundSize: "cover" }}>

                    {Object.keys(this.blob).map((ele, eleI) =>
                        <div key={ele + eleI} className={'transition-' + this.fast + '-linear'} style={{
                            height: 14, width: 14, borderRadius: 12, zIndex: 1, fontSize: 7, color: "#fff", cursor: 'pointer',
                            top: this.blob[ele]["pos"][1] * size + 5, left: this.blob[ele]["pos"][0] * size + 5, position: "absolute"
                        }}>
                            {/* <div className='center-absolute'>{this.blob[ele]['facing']}</div> */}
                            <img alt="blobfish" src={this.blob[ele]['fish']} style={{ height: 14, width: 'auto', transform: "translate(-50%, -50%) " + rotateVal[this.blob[ele]['facing']] }} className="center-absolute" />
                        </div>
                    )}
                    {this.xVal.map((x, xI) =>
                        this.yVal.map((y, yI) =>
                            <div className="unique-map-area" key={"item_" + xI + yI + x + y} style={{
                                top: yI * size + 2, left: xI * size + 2, height: size - 2, width: size - 2,
                                backgroundColor: "#dddddd08"
                            }}>
                                <span className='hover-unique-area'>
                                    {xI} - {yI}<br />
                                    {Object.keys(this.blob).map((ele, eleI) =>
                                        this.blob[ele]['pos'][0] === xI && this.blob[ele]['pos'][1] === yI ? JSON.stringify(this.blob[ele])
                                            : null
                                    )}
                                </span>
                            </div>
                        ))}
                </div>
                <div>
                    {this.state.tickSimulation - this.state.tickDone} / {this.state.tickSimulation}
                </div>
            </div >
        )
    }

}

export default Simu2;
