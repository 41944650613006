
import React, { Component } from 'react';
// import { list_timesup } from "../utils/list_words"
// import { makeid, randMM } from "../utils/utils"
// var Countdown = require('countdown-js')

var lenW = 16
var lenH = 16

/*
    14: 'volcano',
    13: 'high_mountain',
    12: 'mountain',
    11: 'low_mountain',
    10: 'high_hill',
    9: 'hill',
    8: 'plain',
    7: 'low_plain',
    6: 'beach_coast',
    5: 'front_coast',
    4: 'mid_coast',
    3: 'deep_coast',
    2: 'front_ocean',
    1: 'mid_ocean',
    0: 'deep_ocean'
*/

var C = {
    14: '#e63946',
    13: '#e9ecef',
    12: '#adb5bd',
    11: '#ccc15f',
    10: '#bcb954',
    9: '#a5a850',
    8: '#92a54f',
    7: '#86a049',
    6: '#4d68af',
    5: '#3b559b',
    4: '#293f77',
    3: '#24386b',
    2: '#233668',
    1: '#213466',
    0: '#1d2e5b'

}
// var uw = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

class Cities extends Component {

    constructor() {
        super()
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            w: []
        }
        var s = window.innerWidth / lenW
        if (s > 75) s = 75
        this.SIZE = s
    }

    componentDidMount() {
        this.getW()
    }

    getW() {
        var w = []
        for (var x = 0; x < lenW; x++) {
            for (var y = 0; y < lenW; y++) {
                if (x === 0) w.push(0)
                else if (y === 0) w.push(0)
                else if (x === (lenW - 1)) w.push(0)
                else if (y === (lenH - 1)) w.push(0)
                else if (x === 1) w.push(3)
                else if (y === 1) w.push(3)
                else if (x === (lenW - 2)) w.push(3)
                else if (y === (lenH - 2)) w.push(3)
                else if (x === 2) w.push(5)
                else if (y === 2) w.push(5)
                else if (x === (lenW - 3)) w.push(5)
                else if (y === (lenH - 3)) w.push(5)
                else if (x === 3 && y === 3) w.push(5)
                else if (x === (lenW - 4) && y === 3) w.push(5)
                else if (x === 3 && y === (lenH - 4)) w.push(5)
                else if (x === (lenW - 4) && y === (lenH - 4)) w.push(5)
                else w.push(7)
            }
        }
        this.setState({ w: w })
    }

    render() {
        var w = this.state.w
        var SIZE = this.SIZE
        return (
            <div style={{ width: "100%", padding: "25px 20px", position: "relative" }} className='unselect-text'>
                <div className="cities-title">
                    Cities is a new sim game available
                </div>
                <div style={{ width: SIZE * lenW, height: SIZE * lenH, marginTop: 25, marginLeft: "calc(50% - " + ((SIZE * lenW) / 2) + "px)", position: "relative", display: "inline-block" }}>
                    {w.map((e, eI) =>
                        <div key={eI + "_list_w"} style={{ width: SIZE, height: SIZE, display: "inline-block", position: "relative", backgroundColor: C[e], margin: 0, float: "left" }}>
                            <div className="center-absolute" style={{ width: "100%", textAlign: "center" }}>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        )
    }
}

export default Cities
