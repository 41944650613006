
import React, { Component } from 'react';

var _BG = {
    "water": "#427aa1",
    "forest": "#48a12f",
    "plain": "#88d498",
    "mountain": "#dad7cd",
    "city": "#e07a5f"
}

var i_p = "/maps/chunks/"

class Procedural extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            sizeSquare: 0,
            area: [[], []],
            axis: [0, 0],
            line: 0,
            col: 0,
            hideImg: false
        }
        this.index = {}
    }

    componentDidMount() {
        var s = this.getRules(window.innerWidth)
        var line = parseInt(window.innerHeight / s)
        var col = parseInt(window.innerWidth / s)
        this.setState({ totalHeight: window.innerHeight, totalWidth: window.innerWidth, sizeSquare: s, line: line, col: col })
        window.addEventListener('resize', function (event) {
            var s = this.getRules(window.innerWidth)
            var line = parseInt(window.innerHeight / s)
            var col = parseInt(window.innerWidth / s)
            this.setState({ totalHeight: window.innerHeight, totalWidth: window.innerWidth, sizeSquare: s, line: line, col: col })
        }.bind(this));
        window.addEventListener('keydown', function (e) {
            if (e.code === "ArrowUp") this.moveTo("top")
            if (e.code === "ArrowDown") this.moveTo("bottom")
            if (e.code === "ArrowLeft") this.moveTo("left")
            if (e.code === "ArrowRight") this.moveTo("right")
        }.bind(this));
        setTimeout(function () {
            this.generate()
        }.bind(this), 50)
    }

    reload() {
        var s = this.getRules(window.innerWidth)
        var line = parseInt(window.innerHeight / s)
        var col = parseInt(window.innerWidth / s)
        this.setState({ area: [[], []], totalHeight: window.innerHeight, totalWidth: window.innerWidth, sizeSquare: s, line: line, col: col })
        setTimeout(function () {
            this.generate()
        }.bind(this), 50)
    }

    getRules(w) {
        var valW = 0
        // if (w > 1400) valW = 26
        // else if (w > 1000) valW = 20
        // else if (w > 600) valW = 15
        // else valW = 8
        if (w > 1400) valW = 39
        else if (w > 1000) valW = 33
        else if (w > 600) valW = 25
        else valW = 17
        return parseInt(w / valW) + 1
    }

    getParams(arround) {
        var arr = {
            "water": "#427aa1",
            "forest": "#48a12f",
            "plain": "#88d498",
            "mountain": "#dad7cd"
        }
        var possibility = Object.keys(arr)
        possibility.push('plain')
        possibility.push('plain')
        arround.forEach(a => {
            possibility.push(a)
            possibility.push(a)
            possibility.push(a)
            possibility.push(a)
            possibility.push(a)
            possibility.push(a)
            possibility.push(a)
        })
        var r = Math.floor(Math.random() * Math.floor(possibility.length))
        return {
            'bg': arr[possibility[r]],
            "type": possibility[r]
        }
    }

    generate() {
        var a = this.state.area
        var line = this.state.line
        var col = this.state.col
        for (var x = 0; x < line; x++) a[1].push(x)
        for (x = 0; x < col; x++) a[0].push(x)
        a[0].forEach((x, xI) => {
            a[1].forEach((y, yI) => {
                var test_coord = [
                    (xI - 1) + '_' + (yI),
                    (xI) + '_' + (yI - 1),
                    (xI + 1) + '_' + (yI),
                    (xI) + '_' + (yI + 1),
                ]
                var arround = []
                test_coord.forEach(c => {
                    if (this.index[c] !== undefined) {
                        arround.push(this.index[c]['type'])
                    }
                })
                var param = this.getParams(arround)
                this.index[x + '_' + y] = {
                    "bg": param["bg"],
                    "type": param["type"],
                    "id": 'id_' + x + '_' + y,
                }
            })
        })
        this.setState({ area: a })
    }

    moveTo(direction) {
        var a = this.state.area
        var ax = this.state.axis
        var line = this.state.line
        var col = this.state.col
        var nVa = null
        if (direction === 'top') {
            a[0].forEach((x, xI) => {
                if (this.index[x + '_' + (ax[1] - 1)] === undefined) {
                    var arroundT = [this.index[x + "_" + ax[1]]['type']]
                    if (this.index[(x - 1) + "_" + (ax[1] - 1)] !== undefined) arroundT.push(this.index[(x - 1) + "_" + (ax[1] - 1)]['type'])
                    var param = this.getParams(arroundT)
                    this.index[x + '_' + (ax[1] - 1)] = {
                        "bg": param["bg"],
                        "type": param["type"],
                        "id": 'id_' + x + '_' + (ax[1] - 1),
                    }
                }
            })
            ax[1] = ax[1] - 1
            a[1].push(ax[1])
            a[1] = a[1].filter(function (item, pos) {
                return a[1].indexOf(item) === pos;
            })
            a[1].sort(function (a, b) { return a - b })
        }
        if (direction === 'bottom') {
            nVa = line + ax[1]
            a[0].forEach((x, xI) => {
                if (this.index[x + '_' + nVa] === undefined) {
                    var arroundT = [this.index[x + "_" + (nVa - 1)]['type']]
                    if (this.index[(x - 1) + "_" + nVa] !== undefined) arroundT.push(this.index[(x - 1) + "_" + nVa]['type'])
                    var param = this.getParams(arroundT)
                    this.index[x + '_' + nVa] = {
                        "bg": param["bg"],
                        "type": param["type"],
                        "id": 'id_' + x + '_' + nVa,
                    }
                }
            })
            ax[1] = ax[1] + 1
            a[1].push(nVa)
            a[1] = a[1].filter(function (item, pos) {
                return a[1].indexOf(item) === pos;
            })
            a[1].sort(function (a, b) { return a - b })
        }
        if (direction === 'left') {
            a[1].forEach((y, yI) => {
                if (this.index[(ax[0] - 1) + '_' + y] === undefined) {
                    var arroundT = [this.index[ax[0] + "_" + y]['type']]
                    if (this.index[(ax[0] - 1) + "_" + (y - 1)] !== undefined) arroundT.push(this.index[(ax[0] - 1) + "_" + (y - 1)]['type'])
                    var param = this.getParams(arroundT)
                    this.index[(ax[0] - 1) + '_' + y] = {
                        "bg": param["bg"],
                        "type": param["type"],
                        "id": 'id_' + (ax[0] - 1) + '_' + y,
                    }
                }
            })
            ax[0] = ax[0] - 1
            a[0].push(ax[0])
            a[0] = a[0].filter(function (item, pos) {
                return a[0].indexOf(item) === pos;
            })
            a[0].sort(function (a, b) { return a - b })
        }
        if (direction === 'right') {
            nVa = col + ax[0]
            a[1].forEach((y, yI) => {
                if (this.index[nVa + '_' + y] === undefined) {
                    var arroundT = [this.index[(nVa - 1) + "_" + y]['type']]
                    if (this.index[nVa + "_" + (y - 1)] !== undefined) arroundT.push(this.index[nVa + "_" + (y - 1)]['type'])
                    var param = this.getParams(arroundT)
                    this.index[nVa + '_' + y] = {
                        "bg": param["bg"],
                        "type": param["type"],
                        "id": 'id_' + nVa + '_' + y,
                    }
                }
            })
            ax[0] = ax[0] + 1
            a[0].push(nVa)
            a[0] = a[0].filter(function (item, pos) {
                return a[0].indexOf(item) === pos;
            })
            a[0].sort(function (a, b) { return a - b })
        }

        this.setState({ axis: ax, area: a })
    }

    transform(id) {
        // console.log(id)
        if (this.index[id]['type'] === 'city') {
            this.index[id]['type'] = this.index[id]['old_type']
            delete this.index[id]['old_type']
        }
        else if (this.index[id]['type'] === 'water') {
            alert("You can't build city on water !")
        }
        else {
            this.index[id]['old_type'] = this.index[id]['type']
            this.index[id]['type'] = 'city'
        }
        this.setState({ _r: 1 })
    }

    render() {
        var he = this.state.sizeSquare * this.state.line
        var wi = this.state.sizeSquare * this.state.col
        var rot_co = {
            0: "rotate(-90deg)",
            1: "rotate(0deg)",
            2: "rotate(90deg)",
            3: "rotate(180deg)",
        }
        return (
            <div style={{ height: this.state.totalHeight, width: this.state.totalWidth, backgroundColor: "#000", position: 'fixed', top: 0, left: 0, fontFamily: "Marc" }}>
                <div className="transition-100 menu-maps">
                    <div style={{ height: 15, width: 15, backgroundColor: "#666", borderRadius: 3, marginTop: 10, marginBottom: 10, cursor: "pointer" }}>
                    </div>
                    <div style={{ position: 'absolute', top: 8, left: 40, fontSize: 14, letterSpacing: "0.05em", fontFamily: "Do" }}>Origin: {JSON.stringify(this.state.axis)} - Generated: {Object.keys(this.index).length} chunks</div>
                    <div className="transition-100 btn-control-maps" onClick={() => this.reload()}>
                        Reload
                    </div>
                    <div className="transition-100 btn-control-maps" onClick={() => this.setState({ hideImg: !this.state.hideImg })}>
                        {this.state.hideImg ? 'Show image' : 'Hide image'}
                    </div>
                    <br />
                    <div style={{ fontSize: 12 }}>
                        Move with <strong>arrow</strong> on keyboard or these button
                    </div>
                    <div className="transition-100 btn-control-maps" onClick={() => this.moveTo("left")}>
                        Left
                    </div>
                    <div className="transition-100 btn-control-maps" onClick={() => this.moveTo("right")}>
                        Right
                    </div>
                    <div className="transition-100 btn-control-maps" onClick={() => this.moveTo("top")}>
                        Top
                    </div>
                    <div className="transition-100 btn-control-maps" onClick={() => this.moveTo("bottom")}>
                        Bottom
                    </div>
                </div>
                <div className="center-absolute" style={{ height: he, width: wi, zIndex: 1, overflow: "hidden", backgroundColor: "#333" }}>
                    {this.state.area[0].map((x, xI) =>
                        this.state.area[1].map((y, yI) =>
                            x >= this.state.axis[0] && y >= this.state.axis[1] ?
                                <div onClick={() => this.transform(x + '_' + y)} key={x + '_' + y} style={{ height: this.state.sizeSquare, width: this.state.sizeSquare, position: "absolute", left: this.state.sizeSquare * (x - this.state.axis[0]), top: this.state.sizeSquare * (y - this.state.axis[1]), backgroundColor: _BG[this.index[x + '_' + y]['type']], cursor: "pointer" }}>
                                    <div className="center-absolute" style={{ fontSize: 6, zIndex: 1 }}></div>
                                    {this.state.hideImg === true ? null :
                                        // ["water", "plain", "forest"].indexOf(this.index[x + '_' + y]['type']) === -1 ? null :
                                        [(x - 1) + '_' + (y), (x) + '_' + (y - 1), (x + 1) + '_' + (y), (x) + '_' + (y + 1)].map((co, coI) =>
                                            this.index[co] === undefined ?
                                                <img key={x + "_" + y + '_' + coI} alt={x + "_" + y + '_' + coI} src={i_p + this.index[x + '_' + y]['type'] + '_plain.png'} style={{ width: "100%", height: "auto", transform: rot_co[coI], top: 0, left: 0, position: "absolute" }} />
                                                : <img key={x + "_" + y + '_' + coI} alt={x + "_" + y + '_' + coI} src={i_p + this.index[x + '_' + y]['type'] + '_' + this.index[co]['type'] + '.png'} style={{ width: "100%", height: "auto", transform: rot_co[coI], top: 0, left: 0, position: "absolute" }} />
                                        )}
                                </div>
                                : null
                        )
                    )}
                </div>
            </div>
        )
    }

}

export default Procedural;
