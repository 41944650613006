
import React, { Component } from 'react';

var cards = [
    {
        "_id": "0001",
        "occurence": 1,
        'name': "Maladie",
        'action': "Perte 1 villageois",
        "desc": "Si au moins un de vos villageois se trouve sur une case Foret ou Montagne",
        "sub_desc": "Case ressource inactive pour le tour",
        "more": ""
    },
    {
        "_id": "0002",
        "occurence": 1,
        'name': "Naissance",
        "action": "Gain 1 villageois",
        "desc": "Si vous avez au moins 2 villageois dans votre ville",
        "sub_desc": "Ajouter immédiatement un villageois a votre ville",
        "more": "Ajoutez 2 villageois si vous possédez un Centre Ville",
    },
    {
        "_id": "0003",
        "occurence": 3,
        'name': "Adoption",
        "action": "Gain 1 villageois",
        "desc": "Si vous avez au moins 1 villageois dans votre ville",
        "sub_desc": "Ajouter immédiatement un villageois a votre ville",
        "more": "",
    },
    {
        "_id": "0004",
        "occurence": 1,
        'name': "Baby Boom",
        "action": "Gain 2 villageois",
        "desc": "Si vous avez au moins 2 villageois dans votre ville",
        "sub_desc": "Ajouter immédiatement 2 villageois a votre ville",
        "more": "Ajoutez 3 villageois si vous possédez un Centre Ville",
    },
    {
        "_id": "0005",
        "occurence": 5,
        'name': "Pillage",
        "action": "Perte 1 ressource",
        "desc": "Si au moins un de vos villageois se trouve sur une case Foret, Montagne ou Prairie",
        "sub_desc": "Défaussez vous immédiatement d'une ressource de votre choix",
        "more": "",
    },
    {
        "_id": "0006",
        "occurence": 6,
        'name': "Menuisier",
        "action": "Gain 1 bois",
        "desc": "Si au moins un de vos villageois se trouve sur une case Foret",
        "sub_desc": "Ajoutez immédiatement un bois à votre réserve",
        "more": "Ajoutez en 2 si vous possédez au moins un atelier en Foret",
    },
    {
        "_id": "0007",
        "occurence": 6,
        'name': "Tailleur de pierre",
        "action": "Gain 1 pierre",
        "desc": "Si au moins un de vos villageois se trouve sur une case Montagne",
        "sub_desc": "Ajoutez immédiatement une pierre à votre réserve",
        "more": "Ajoutez en 2 si vous possédez au moins un atelier en Montagne",
    },
    {
        "_id": "0008",
        "occurence": 6,
        'name': "Sol fertile",
        "action": "Gain 1 céréale",
        "desc": "Si au moins un de vos villageois se trouve sur une case Prairie",
        "sub_desc": "Ajoutez immédiatement une céréale à votre réserve",
        "more": "Ajoutez en 2 si vous possédez au moins un atelier en Prairie",
    },
    {
        "_id": "0009",
        "occurence": 1,
        'name': "Mousson",
        "action": "Gain 3 céréales / Perte 1 villageois",
        "desc": "Si au moins un de vos villageois se trouve sur une case Prairie",
        "sub_desc": "Ajoutez immédiatement 3 céréales à votre réserve. Enlever un villageois sur une case prairie.",
        "more": "Si votre case Prairie comporte un atelier, choisissez de perdre soit le villageois, soit l'atelier",
    },
    {
        "_id": "0010",
        "occurence": 1,
        'name': "Tsunami",
        "action": "Perte 2 villageois ET 1 marchand",
        "desc": "Si votre ville comporte un port de commerce, appliqué la perte du marchand, sinon uniquement les villageois",
        "sub_desc": "Si votre marchand se trouve dans une ville (la vôtre ou une des autres villes du monde), celui-ci disparait.",
        "more": "",
    },
    {
        "_id": "0011",
        "occurence": 4,
        'name': "Nouvelle roue",
        "action": "1 mouvement en plus pour un transport",
        "desc": "Si vous possédez un transport vide sur votre territoire",
        "sub_desc": "Un de vos transports peut se déplacer une fois de plus pendant ce tour",
        "more": "",
    }
]

function _RAND(min, max) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min);
}
function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

class EventCard extends Component {

    constructor() {
        super()
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            card: null,
            picking: false,
            allCards: [],
            indexCard: 0
        }
    }

    componentDidMount() {
        var allCards = []
        cards.forEach(c => {
            for (var x = 1; x <= c['occurence']; x++) { c['state'] = 0; allCards.push(JSON.parse(JSON.stringify(c))) }
        })
        allCards = shuffle(allCards)
        console.log(allCards)
        this.setState({ allCards: allCards, indexCard: 0 })
    }

    resetCards() {
        var allCards = []
        cards.forEach(c => {
            for (var x = 1; x <= c['occurence']; x++) { c['state'] = 0; allCards.push(JSON.parse(JSON.stringify(c))) }
        })
        allCards = shuffle(allCards)
        console.log(allCards)
        this.setState({ allCards: allCards, indexCard: 0 })
    }

    selectRandomCard() {
        this.setState({ card: null, picking: true })
        setTimeout(function () {
            this.setState({ card: this.state.allCards[_RAND(0, this.state.allCards.length - 1)], picking: false })
        }.bind(this), 500)
    }

    updateStateCard(index) {
        var allCards = this.state.allCards
        var _I = this.state.indexCard
        allCards[index]['state'] = allCards[index]['state'] + 1
        if (allCards[index]['state'] === 1) _I = _I + 1
        this.setState({ allCards: allCards, indexCard: _I })
    }

    render() {
        // var _CARD = this.state.card
        var _CARDS = this.state.allCards
        return (
            <div style={{ width: "100%", padding: "25px 20px", minHeight: 750, position: "relative", overflowY: "hidden", overflowX: "hidden" }} className='unselect-text'>
                {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{ marginBottom: 20, fontFamily: "DoSemiBold", fontSize: 20, letterSpacing: "0.04em" }}>
                    EventCard
                </div> */}
                {/* <div style={{ width: 320, margin: "auto" }}>
                    <div style={{ display: "inline-flex", textAlign: "center", width: 320 }}>
                        <div className="btn_event_card" onClick={() => this.selectRandomCard()}>Pick a card</div>
                        <div className="btn_event_card" onClick={() => this.setState({ card: null })}>Reset card</div>
                    </div>
                </div> */}
                <div className="index_cards_position_bot">{this.state.indexCard} / {_CARDS.length}</div>
                <div style={{ width: 320, margin: "auto" }}>
                    <div style={{ display: "inline-flex", textAlign: "center", width: 320 }}>
                        <div className="btn_event_card" style={{ marginTop: 0 }} onClick={() => this.resetCards()}>Reset card</div>
                    </div>
                </div>
                <div className="text-center" style={{ margin: "auto", marginTop: 10, position: "relative" }}>
                    {_CARDS.map((_c, _cI) =>
                        <div key={'list_card_' + _cI} onClick={() => this.updateStateCard(_cI)}
                            style={{
                                zIndex: _CARDS.length - _cI, top: _cI * 3, left: 'calc(48% + ' + _cI + "px)",
                                backgroundImage: "url(/card_front.png)", backgroundPosition: "center", backgroundSize: "cover",
                                backgroundRepeat: "no-repeat"
                            }}
                            className={"card_state_event_card transition-500 card_state_" + _c['state'] + "_event_card"}>
                            <div className="title_event_card">
                                {_c['name']}
                            </div>
                            <div className="desc_event_card">
                                <span className="title_desc_event_card">Action:</span>
                                {_c['action']}
                            </div>
                            <div className="desc_event_card">
                                <span className="title_desc_event_card">Condition:</span>
                                {_c['desc']}
                            </div>
                            <div className="desc_event_card">
                                <span className="title_desc_event_card">Effet:</span>
                                {_c['sub_desc']}
                            </div>
                            {_c['more'] === '' ? null :
                                <div className="desc_event_card">
                                    <span className="title_desc_event_card">Effet special:</span>
                                    {_c['more']}
                                </div>
                            }
                            <div className="id_event_card">
                                CARD_ID_{_c['_id']}
                            </div>
                        </div>
                    )}
                    {_CARDS.map((_c, _cI) =>
                        <div key={'list_card_2_' + _cI} onClick={() => this.updateStateCard(_cI)}
                            style={{
                                zIndex: _CARDS.length - _cI, top: _cI * 3, left: 'calc(48% + ' + _cI + "px)",
                                filter: _cI > this.state.indexCard ? "brightness(0.6) sepia(0.5)" : "brightness(1) sepia(0.5)",
                                backgroundImage: "url(/card_back.png)", backgroundPosition: "center", backgroundSize: "cover",
                                backgroundRepeat: "no-repeat"
                            }}
                            className={"card_state_event_card transition-500 card_state_" + _c['state'] + "_event_card card_state_" + _c['state'] + "_event_card_1"}>
                            <div className="center-absolute click_card_center">Click to pick a card</div>
                        </div>
                    )}
                    {/* _CARD === null ?
                        <div className="size_event_card">{this.state.picking ? <div className="title_event_card">Picking a card ...</div> : <div className="title_event_card">No card picked</div>}</div> :
                        <div className="size_event_card">
                            <div className="title_event_card">
                                {_CARD['name']}
                            </div>
                            <div className="desc_event_card">
                                <span className="title_desc_event_card">Action:</span>
                                {_CARD['action']}
                            </div>
                            <div className="desc_event_card">
                                <span className="title_desc_event_card">Condition:</span>
                                {_CARD['desc']}
                            </div>
                            <div className="desc_event_card">
                                <span className="title_desc_event_card">Effet:</span>
                                {_CARD['sub_desc']}
                            </div>
                            {_CARD['more'] === '' ? null :
                                <div className="desc_event_card">
                                    <span className="title_desc_event_card">Effet special:</span>
                                    {_CARD['more']}
                                </div>
                            }
                            <div className="id_event_card">
                                CARD_ID_{_CARD['_id']}
                            </div>
                        </div>
                     */}
                </div>
            </div >
        )
    }
}

export default EventCard
