import React, { Component } from 'react';

class Creations extends Component {

    render() {
        return (
            <div className="container" style={{ paddingTop: 40, maxWidth: 700, paddingBottom: 80, position: 'relative' }}>
                <div style={{ position: 'absolute', bottom: 10, display: "inline-flex", paddingLeft: 17 }}>
                    <a href="https://github.com/ncailleux" target="_blank" rel="noopener noreferrer" style={{ color: "#555", marginRight: 10, cursor: "pointer" }}>
                        _git
                    </a>
                    <a href="https://twitter.com/_Criquet_" target="_blank" rel="noopener noreferrer" style={{ color: "#555", marginRight: 10, cursor: "pointer" }}>
                        _twitter
                    </a>
                    <a href="https://www.instagram.com/justsomedrawingstories" target="_blank" rel="noopener noreferrer" style={{ color: "#555", marginRight: 10, cursor: "pointer" }}>
                        _instagram
                    </a>
                    <a href="https://sommelierduparfum.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#555", marginRight: 10, cursor: "pointer" }}>
                        _work
                    </a>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{ marginBottom: 20, fontFamily: "SP", fontSize: 20, letterSpacing: "0.04em" }}>
                    Stuff
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding text-center" style={{}}>
                    <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href='/yatz'  /* to={{ pathname: '/yatz', state: { "id": "yatz" } }} */>
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Play Yahtzee !
                        </div>
                    </a>
                    {/* <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/tiles">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Tiles Game
                        </div>
                    </a> */}
                    {/* <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/worldgame">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            World Game
                        </div>
                    </a> */}
                    <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/word">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Word finder
                        </div>
                    </a>
                    <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/random_world_generator">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Random World Generator
                        </div>
                    </a>
                    {/* <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/maps">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Maps
                        </div>
                    </a>
                    <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/files">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Files
                        </div>
                    </a> */}
                    <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/fight">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            PixFight
                        </div>
                    </a>
                    {/* <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/cities">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Cities
                        </div>
                    </a> */}
                    <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/workout">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Workout
                        </div>
                    </a>
                    {/* <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/coco">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Simu Test
                        </div>
                    </a> */}
                    {/* <a className="no-underline-hover col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center" href="/civ">
                        <div className="btn-creation transition-100 " style={{ width: "90%" }}>
                            Civ (test)
                        </div>
                    </a> */}
                </div>
                <a href="/eventcard" className="no-underline-hover" style={{ position: "fixed", bottom: 0, right: 2, fontSize: 8, color: "#888" }}>
                    EC
                </a>
            </div>
        )
    }
}

export default Creations
