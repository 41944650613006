
import React, { Component } from 'react';

class Files extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
    }

    componentDidMount() {
        var files_01 = require('./utils/files/files_01.json');
        var files_show = []
        files_01.forEach(ele => {
            if (ele['draft'] === false) files_show.push(ele)
        })
        this.setState({ files: files_show })
    }

    render() {
        return (
            <div style={{ minHeight: window.innerHeight, backgroundColor: "#fafafa", paddingBottom: 100 }}>
                <div className="container" style={{ position: "relative" }}>
                    <img alt="quit_onboard" src="/quit_onboard.svg" className="close-btn-work" style={{ left: 30, height: 13, top: 24, position: 'absolute' }} onClick={() => window.location = '/'} />
                    <div className="text-center" style={{ fontSize: 20, paddingTop: 20, fontFamily: "Meri" }}>
                        Files
                    </div>
                    <div style={{ marginTop: 50 }}>
                        {this.state.files.length === 0 ?
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{ marginTop: 50, fontFamily: "Marc", fontSize: 22, letterSpacing: "0.3em" }}>
                                No files to read yet, coming soon.
                            </div>
                            : this.state.files.map((f, fI) =>
                                <a href={"/files/" + f['id']} key={"file_number_" + fI} className="no-underline-hover">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row card-one-file transition-300">
                                        {fI % 2 === 0 ?
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 no-padding bg-card" style={{ backgroundImage: `url(${f['image']})` }}>
                                            </div>
                                            :
                                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-8 content-card" style={{ paddingRight: 20, paddingLeft: 15, textAlign: "right" }}>
                                                <div className="title-card" >
                                                    {f['name']}
                                                </div>
                                                <div className="font-l" dangerouslySetInnerHTML={{ __html: f['short'] }} style={{ paddingTop: 7 }}>
                                                </div>
                                                <div className="tags">
                                                    {f['topic'].map((t, tI) =>
                                                        <span style={{ paddingRight: 6 }}>#{t}</span>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        {fI % 2 === 0 ?
                                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-8 content-card" style={{ paddingLeft: 20, paddingRight: 15 }}>
                                                <div className="title-card" >
                                                    {f['name']}
                                                </div>
                                                <div className="font-l" dangerouslySetInnerHTML={{ __html: f['short'] }} style={{ paddingTop: 7 }}>
                                                </div>
                                                <div className="tags">
                                                    {f['topic'].map((t, tI) =>
                                                        <span style={{ paddingRight: 6 }}>#{t}</span>
                                                    )}
                                                </div>
                                            </div>
                                            :
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 no-padding bg-card" style={{ backgroundImage: `url(${f['image']})` }}>
                                            </div>
                                        }
                                    </div>
                                </a>
                            )}
                    </div>
                </div>
            </div>
        )
    }

}

export default Files;
