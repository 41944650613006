
import React, { Component } from 'react';
import { makeid, randMM } from "./utils/utils"
var Countdown = require('countdown-js')

var drops = {
    0: {
        'type': "gold",
        'ammount': 4
    },
    1: {
        'type': "gold",
        'ammount': 12
    },
    2: {
        'type': "gold",
        'ammount': 25
    },
    3: {
        'type': "gold",
        'ammount': 1
    },
    4: {
        'type': "tile",
        'ammount': 1
    },
    5: {
        'type': "tile",
        'ammount': 0
    }
}

class Tiles extends Component {

    constructor() {
        super()
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            account: {
                "cd": [],
                "gold": 50,
                "tile": 0,
                'id': null
            },
            cat: "tiles"
        }
    }

    componentDidMount() {
        // var localAcc = localStorage.setItem('account_tiles_drop', null)
        var localAcc = localStorage.getItem('account_tiles_drop')
        var lnno = ['null', null, "undefined", undefined]
        if (lnno.indexOf(localAcc) === -1) {
            var new_acc = JSON.parse(localAcc)
            new_acc['cd'].forEach((ele, eleI) => {
                new_acc['cd'][eleI]['end'] = new Date(new_acc['cd'][eleI]['end'])
                new_acc['cd'][eleI]['too_late'] = new Date(new_acc['cd'][eleI]['too_late'])
            })
            this.setState({ account: new_acc })
        }
        else {
            this.setState({ account: { "cd": [], "gold": 50, "tile": 0, 'id': makeid(21, []) } })
            this.save()
        }
    }

    save() {
        setTimeout(function () {
            localStorage.setItem('account_tiles_drop', JSON.stringify(this.state.account))
        }.bind(this), 50)
    }

    generateRand(len) {
        var acc = this.state.account
        if ((acc['gold'] > 9 && len === 1) || (acc['gold'] > 89 && len === 10)) {
            for (var i = 0; i < len; i++) {
                var moreDate = randMM(450000, 1000000)
                var end = new Date(new Date().getTime() + moreDate)
                var too_late = new Date(new Date().getTime() + (moreDate + randMM(300000, 600000)))
                var tmp_cd = {
                    'name': "First",
                    "type": randMM(0, 5),
                    'end': end,
                    'too_late': too_late,
                    "id": makeid(5, this.state.account['cd'])
                }
                acc['cd'].push(tmp_cd)
            }
            if (len === 1) acc['gold'] = acc['gold'] - 10
            if (len === 10) acc['gold'] = acc['gold'] - 90
            this.setState({ account: acc })
            this.save()
        }
        else alert('Not enough gold !')
    }

    getTimer(type, end, too_late, id) {
        var t = ""
        end = new Date(end.toString())
        too_late = new Date(too_late.toString())
        if (type === 'end') {
            Countdown.timer(end, function (timeLeft) {
                var s = timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds
                var m = timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes
                var h = timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours
                t = h + ':' + m + ":" + s + ""
                var d = document.getElementById('countdown_' + id)
                if (d) document.getElementById('countdown_' + id).innerHTML = t
            }, function () {
                var d = document.getElementById('countdown_' + id)
                if (d) document.getElementById('countdown_' + id).innerHTML = "Available"
                d = document.getElementById('timer_' + id)
                if (d) document.getElementById('timer_' + id).onclick = function () { this.actionCd(id, true) }.bind(this)
                d = document.getElementById('timer_id_' + id)
                if (d) document.getElementById('timer_id_' + id).className = "id_timer_done transition-300"
            }.bind(this))
            this.getTimer("too_late", end, too_late, id)
        }
        else {
            Countdown.timer(too_late, function (timeLeft) {
                var s = timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds
                var m = timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes
                var h = timeLeft.hours < 10 ? "0" + timeLeft.hours : timeLeft.hours
                t = h + ':' + m + ":" + s + ""
            }, function () {
                var d = document.getElementById('countdown_' + id)
                if (d) document.getElementById('countdown_' + id).innerHTML = "Too late..."
                d = document.getElementById('timer_' + id)
                if (d) document.getElementById('timer_' + id).onclick = function () { this.actionCd(id, false) }.bind(this)
                d = document.getElementById('timer_id_' + id)
                if (d) document.getElementById('timer_id_' + id).className = "id_timer_toolate transition-300"
            }.bind(this))
        }
    }

    actionCd(id, good) {
        var AC = this.state.account
        var toDel = null
        AC['cd'].forEach((ele, eleI) => {
            if (ele['id'] === id) {
                toDel = eleI
                if (good) AC[drops[ele['type']]['type']] = AC[drops[ele['type']]['type']] + drops[ele['type']]['ammount']
            }
        })
        if (toDel !== null) AC['cd'].splice(toDel, 1)
        this.setState({ account: AC })
        this.save()
    }

    render() {
        var AC = this.state.account
        return (
            <div style={{ width: "100%", padding: "25px 20px", position: "relative" }} className='unselect-text'>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center" style={{ marginBottom: 20, fontFamily: "DoSemiBold", fontSize: 20, letterSpacing: "0.04em" }}>
                    Tiles
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="sub_title_timer">
                            Specs
                        </div>
                        <div>
                            <div>
                                Gold: {AC['gold']}
                            </div>
                            <div>
                                Tile: {AC['tile']}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="sub_title_timer">
                            Action
                        </div>
                        <div>
                            <div className="transition-300" style={{ cursor: "pointer", color: AC['gold'] > 9 ? "#000" : "#6e6e6e" }} onClick={() => this.generateRand(1)}>
                                Generate Rand (cost: 10 gold)
                            </div>
                            <div className="transition-300" style={{ cursor: "pointer", color: AC['gold'] > 89 ? "#000" : "#6e6e6e" }} onClick={() => this.generateRand(10)}>
                                Generate Rand x10 (cost: 90 gold)
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding" style={{ marginTop: 35 }}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 no-padding">
                        <div className={this.state.cat === 'tiles' ? "sub_title_timer_btn-sel transition-300" : "sub_title_timer_btn transition-300"} style={{ borderRight: "1px solid #ddd" }} onClick={() => this.setState({ cat: 'tiles' })}>
                            <div className={this.state.cat === 'tiles' ? "bck-abs-btn-tiles-s center-absolute transition-100" : "bck-abs-btn-tiles center-absolute transition-100"}></div>
                            TILES
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 no-padding">
                        <div className={this.state.cat === 'drops' ? "sub_title_timer_btn-sel transition-300" : "sub_title_timer_btn transition-300"} style={{ borderLeft: "1px solid #ddd" }} onClick={() => this.setState({ cat: 'drops' })}>
                            <div className={this.state.cat === 'drops' ? "bck-abs-btn-tiles-s center-absolute transition-100" : "bck-abs-btn-tiles center-absolute transition-100"}></div>
                            DROPS ({AC['cd'].length})
                        </div>
                    </div>
                </div>
                <div className={this.state.cat === 'tiles' ? "select-cat transition-500" : "unselect-cat transition-500"}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding" style={{ marginTop: 15 }}>
                        It will be usefull soon. You got {AC['tile']} as for now.
                    </div>
                </div>
                <div className={this.state.cat === 'drops' ? "select-cat-2 transition-500" : "unselect-cat-2 transition-500"}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding" style={{}}>
                        {AC['cd'].map((cd, cdI) =>
                            <div onLoadCapture={this.getTimer("end", cd['end'], cd['too_late'], cd['id'])}
                                key={'timer_' + cd['id']} id={'timer_' + cd['id']} className="timer_unique col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6">
                                <div id={'timer_id_' + cd['id']} className="id_timer transition-300">{cd['id']}</div>
                                <div className="count_timer" id={"countdown_" + cd['id']}></div>
                            </div>
                        )}
                    </div>
                </div>
            </div >
        )
    }
}

export default Tiles
