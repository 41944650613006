import React, { Component } from 'react';
import { HexGrid, Layout/* , Hexagon, Text */ /*, Pattern, Path, Hex */ } from 'react-hexgrid';
import { compressToBase64, decompressFromBase64 } from 'lz-string'
import noise from './utils/noise.js'
import { builds, /* defaultColors, */ defaultColorsType, defaultType } from "./utils/worldgame_utils.js"
import WGTile from './WGTile.js';
// import { param } from 'jquery';

var season = {
    0: "Spring",
    1: "Summer",
    2: "Autumn",
    3: "Winter"
}

class WorldGame2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            area: [],
            userInfo: {
                point: 0,
                money: 3
            },
            openPanel: false,
            key: "",
            arroundSelect: [],
            effect: null,
            time: {
                year: 0,
                season: 0
            },
            selected: null,
            defaultOptions: {
                width: 40,
                height: 60,
                noiseReduction: 8,
                seed: 0,
                size: 0.3,
                elevation: 3.4,
                lakeSize: 3,
                curve: 0.8
            }
        }
        this.index = {}
        this.size = [54, 36]
        this.uniqueSize = 18
    }

    componentDidMount() {
        this.generate()
        window.addEventListener('keydown', function (e) {
            if (e.code === "ArrowRight") this.tempoUpdate()
        }.bind(this));
        window.addEventListener('resize', function (event) {
            this.setState({ totalHeight: window.innerHeight, totalWidth: window.innerWidth })
        }.bind(this));
    }

    save() {
        var save = {
            "area": this.state.area,
            "params": {}
        }
        var saveText = JSON.stringify(save)
        var encodeTxt = compressToBase64(saveText)
        localStorage.setItem("save_worldgame", encodeTxt)
        this.setState({ key: encodeTxt })
    }

    loadKey() {
        var text = document.getElementById('input-key').value
        try {
            var bcP = JSON.parse(decompressFromBase64(text))
            this.setState({ area: bcP['area'] })
            document.getElementById('input-key').value = ""
        } catch {
            alert("Wrong save")
        }
    }

    copyCode() {
        const el = document.createElement('textarea');
        el.value = document.getElementById('input-key-save').value
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        // copyText.setSelectionRange(0, 99999);
        console.log('copied !')
    }

    generateMap(options) {
        // github crusoe helped
        options = Object.assign({}, this.state.defaultOptions, options)

        var width = options.width
        var height = options.height
        var seed = options.seed
        var size = options.size - 1
        var curve = options.curve * 2
        var elevation = -2 + options.elevation
        var lakeSize = options.lakeSize
        var scale = options.noiseReduction
        var scaleHalf = scale / 2
        var scale2 = scale * 2

        var islandW = width * 0.5
        var islandH = height * 0.5
        var diagonalHalf = Math.sqrt(width * width + height * height)

        noise.seed(seed)

        var data = []

        for (var x = 0; x < width; x++) {
            data[x] = []

            for (var y = 0; y < height; y++) {
                var dist = Math.sqrt(Math.pow((x - width / 2) / islandW, 2) + Math.pow((y - height / 2) / islandH, 2))
                var islandFactor = (dist < diagonalHalf ? Math.cos(dist * Math.PI / 2) + size : -10) * 4 * curve

                var terrainFactor = (noise.simplex2(x / scale, y / scale) + noise.perlin2(x / scaleHalf, y / scaleHalf)) / 3
                var lakeFactor = islandFactor > 0 ? noise.perlin2(x / scale2, y / scale2) * lakeSize * islandFactor : 0

                var n = (islandFactor + terrainFactor + lakeFactor) / 3

                if (n < 0) {
                    data[x][y] = n
                } else {
                    // Land
                    var climateFactor = ((noise.simplex2(x / scale, y / scale) + noise.perlin2(x / scale2, y / scale2)) / 2 + elevation) / 2 + islandFactor / (50 / curve)
                    if (climateFactor < 0) {
                        climateFactor = 0
                    }

                    data[x][y] = climateFactor
                }
            }
        }
        return { data: data, width: width, height: height }
    }

    getType(n) {
        if (n > 0.985) return defaultType.AAA
        if (n > 0.95) return defaultType.AA
        else if (n > 0.9) return defaultType.A
        else if (n > 0.8) return defaultType.B
        else if (n > 0.75) return defaultType.C
        else if (n > 0.65) return defaultType.D
        else if (n > 0) return defaultType.E
        else if (n > -0.1) return defaultType.F
        else if (n > -0.15) return defaultType.G
        else if (n > -0.2) return defaultType.H
        else if (n > -0.25) return defaultType.I
        else if (n > -0.3) return defaultType.J
        else if (n > -0.35) return defaultType.K
        else return defaultType.L
    }

    renderMap(map) {
        var isl = []
        var colors = []
        for (var x = 0; x < map.width; x++) {
            colors[x] = []
            isl[x] = []
            for (var y = 0; y < map.height; y++) {
                var type = this.getType(map.data[x][y])
                if (['high_mountain'].indexOf(type) !== -1) {
                    if (Math.floor(Math.random() * Math.floor(40)) === 1) type = "volcano"
                }
                // colors[x][y] = this.getColor(map.data[x][y], colorMap, type)
                colors[x][y] = defaultColorsType[type]
                isl[x][y] = {
                    "type": type,
                    "id": x + '-' + y
                }
            }
        }

        return {
            width: map.width,
            height: map.height,
            colors: colors,
            isl: isl
        }
    }

    generate() {
        var map = this.renderMap(this.generateMap({ seed: Date.now() }), {})
        var _hexa = []
        var xMax = this.size[0]
        var yMax = this.size[1]
        var _A = map['isl']
        var i = 0
        if (_A.length > 0) {
            for (var x = 0; x < xMax; x++) {
                for (var y = 0; y < yMax; y++) {
                    var xx = x
                    var yy = y
                    var param = { 'type': "null" }
                    if (_A[y] !== undefined) param = _A[y][x]
                    var luck_treasure = 10
                    var add_treasure = Math.floor(Math.random() * Math.floor(luck_treasure))
                    if (['beach_coast'].indexOf(param['type']) !== -1) {
                        if (add_treasure === 1) param['special'] = "treasure"
                    }
                    add_treasure = Math.floor(Math.random() * Math.floor(luck_treasure + 5))
                    if (['plain'].indexOf(param['type']) !== -1) {
                        if (add_treasure === 1) param['special'] = "temple"
                    }
                    var item = {
                        "id": i,
                        "params": param
                    }
                    if (x <= 1) {
                        if (y !== yMax) {
                            item['coord'] = [xx, yy, 0]
                            _hexa.push(item)
                        }
                    }
                    else {
                        if (xx % 2 === 0) item['coord'] = [xx, yy - xx / 2, 0]
                        else item['coord'] = [xx, yy - (xx - 1) / 2, 0]
                        _hexa.push(item)
                    }
                    i = i + 1
                }
            }
        }
        this.setState({ area: _hexa })
    }

    updateValue(type, val) {
        var opt = this.state.defaultOptions
        opt[type] = parseFloat(val)
        this.setState({ defaultOptions: opt })
    }

    handleClick(id, coord) {
        console.log(coord)
        if (this.state.arroundSelect.length > 0) {
            var c = coord[0] + "-" + coord[1]
            if (this.state.arroundSelect.indexOf(c) !== -1) {
                console.log("Good")
            }
            else {
                this.setState({ arroundSelect: [], selected: this.state.selected === id ? null : id })
            }
        }
        else this.setState({ selected: this.state.selected === id ? null : id })
    }

    handleAddCity() {
        var build_name = "camp_1"
        var id = JSON.parse(JSON.stringify(this.state.selected))
        var gr = JSON.parse(JSON.stringify(this.state.area))
        var effect = null
        var type = gr[id]["params"]['type']
        if (["beach_coast", "front_coast", "mid_coast", "deep_coast", "front_ocean", "mid_ocean", "deep_ocean", "high_mountain"].indexOf(type) !== -1) {
            alert('Cant build here')
        }
        else {
            var params = gr[id]["params"]
            params['build'] = build_name
            params['level'] = 1
            // params['pop'] = 4
            params["name"] = builds[build_name]['level'][params['level']]['name']
            params["pop"] = builds[build_name]['level'][params['level']]['pop']
            params["ruin"] = builds[build_name]['level'][params['level']]['ruin']
            params["wealth"] = builds[build_name]['level'][params['level']]['wealth']
            gr[id]["params"] = params
            effect = id
        }
        this.setState({ area: gr, effect: effect })
        setTimeout(function () {
            this.setState({ effect: null })
        }.bind(this), 300)
    }

    tempoUpdate() {
        var gr = JSON.parse(JSON.stringify(this.state.area))
        var ui = JSON.parse(JSON.stringify(this.state.userInfo))
        var done = false
        var doneU = false
        gr.forEach((ele, eleI) => {
            if (ele["params"]['build']) {
                var i = builds[ele["params"]['build']]['level'][ele["params"]['level']]
                if (i !== undefined) {
                    doneU = true
                    ui['point'] = ui['point'] + Math.round(ele["params"]['pop'] * i['gp_1'] + i['gp_2'])
                    if (ele["params"]['pop'] < i['max_pop']) {
                        var newPop = i['coef_evolve_pop'] - ele["params"]['pop'] - ele["params"]['wealth'] + ele["params"]['ruin']
                        if (newPop < 2) newPop = 2
                        var newPopRandom = Math.floor(Math.random() * Math.floor(newPop))
                        if (newPopRandom === 1) {
                            ele["params"]['pop'] = ele["params"]['pop'] + 1
                            done = true
                        }
                    }
                }
            }
        })
        gr.forEach(ele => {
            if (ele["params"]['build']) {
                var i = builds[ele["params"]['build']]['level'][ele["params"]['level']]
                if (i !== undefined) {
                    if (i["min_evolve_pop"] <= ele["params"]['pop']) {
                        var luck = i["coef_evolve_build"] - ele["params"]['pop'] - ele["params"]['wealth'] + ele["params"]['ruin']
                        if (luck < 3) luck = 3
                        var r = Math.floor(Math.random() * Math.floor(luck))
                        if (r === 0) {
                            done = true
                            ele["params"]['level'] = ele["params"]['level'] + 1
                            doneU = true
                            ui['money'] = ui['money'] + 1
                        }
                        if (ele["params"]['level'] > 3) {
                            ele["params"]['level'] = 2
                            done = false
                        }
                    }
                }
            }
        })
        var nt = this.state.time
        nt['season'] = nt['season'] + 1
        if (nt['season'] > 3) {
            nt['season'] = 0
            nt['year'] = nt['year'] + 1
        }
        if (done === true) {
            if (doneU === true) this.setState({ area: gr, time: nt, userInfo: ui })
            else this.setState({ area: gr, time: nt })
        }
        else {
            if (doneU === true) this.setState({ time: nt, userInfo: ui })
            else this.setState({ time: nt })
        }
        console.log("done")
    }

    getTotalPop() {
        var gr = JSON.parse(JSON.stringify(this.state.area))
        var p = 0
        gr.forEach((ele, eleI) => { if (ele["params"]['build']) p = p + ele["params"]['pop'] })
        return p
    }

    getArround(id, radius) {
        var gr = this.state.area
        var x_ = gr[id]['coord'][0]
        var y_ = gr[id]['coord'][1]
        var arroundSelect = []
        var arround = [[0, -1], [1, -1], [1, 0], [0, 1], [-1, 1], [-1, 0]]
        if (radius >= 2) arround = arround.concat([[0, -2], [1, -2], [2, -2], [2, -1], [2, 0], [1, 1], [0, 2], [-1, 2], [-2, 2], [-2, 1], [-2, 0], [-1, -1]])
        if (radius > 2) arround = arround.concat([[0, -3], [1, -3], [2, -3], [3, -3], [3, -2], [3, -1], [3, 0], [2, 1], [1, 2], [0, 3], [-1, 3], [-2, 3], [-3, 3], [-3, 2], [-3, 1], [-3, 0], [-2, -1], [-1, -2]])
        arround.forEach(ele => {
            arroundSelect.push((x_ + ele[0]) + '-' + (y_ + ele[1]))
        })
        this.setState({ arroundSelect: arroundSelect })
        // setTimeout(function () { this.setState({ arroundSelect: [] }) }.bind(this), 2000)
    }

    render() {
        var _A = this.state.area
        var _ID = this.state.selected
        return (
            <div className="change-hexa" style={{ minHeight: this.state.totalHeight, backgroundColor: "#1d2e5b", fontFamily: "Marc", textAlign: "center", overflowX: "auto" }}>
                <div style={{ position: "fixed", top: 10, left: 10, color: "#fff", fontFamily: "Do" }}>
                    {season[this.state.time['season']] + " - Year " + (this.state.time['year'] + 1)}
                </div>
                <div style={{ position: "fixed", top: 35, left: 10, color: "#fff", fontFamily: "Do", cursor: 'pointer', textDecoration: "underline" }} onClick={() => this.tempoUpdate()}>
                    Next season...
                </div>
                <div style={{ position: "fixed", top: 10, left: "50%", transform: 'translateX(-50%)', color: "#fff", fontFamily: "Do", cursor: 'pointer' }}>
                    {this.state.userInfo['point'] + ' Pts   -   ' + this.state.userInfo['money'] + ' Gold   -   ' + this.getTotalPop() + ' Population'}
                </div>
                <div style={{ position: "fixed", top: 10, right: 10, color: "#fff", fontFamily: "Do", cursor: 'pointer', textDecoration: "underline" }} onClick={() => this.setState({ openPanel: !this.state.openPanel })}>
                    Panel
                </div>
                {_A.length > 0 ?
                    <HexGrid width={1500} height={1000} viewBox="160 75 250 270" style={{ transform: "scale(0.5)" }}>
                        <Layout size={{ x: 6, y: 6 }} flat={true} spacing={0.98} origin={{ x: 0, y: 0 }}>
                            {_A.map((h, hI) =>
                                <WGTile key={hI} h={h} hI={hI} selected={this.state.selected} arroundSelect={this.state.arroundSelect} effect={this.state.effect} handleClick={this.handleClick.bind(this)} />
                                // <Hexagon key={hI} q={h['coord'][0]} r={h['coord'][1]} s={h['coord'][2]} className={"hover-info-params-action change-hexa-color-" + h["params"]['type']} onClick={() => this.handleClick(hI)}>
                                //     {h["params"]['type'] === "low_plain" ?
                                //         <image xlinkHref={`/maps/city/hexa_2.png`} height="12.7" width="13.6" x="-6.9" y="-6.3" />
                                //         : h["params"]['type'] === "plain" ?
                                //             <image xlinkHref={`/maps/city/hexa_3.png`} height="12.7" width="13.6" x="-6.9" y="-6.3" />
                                //             : null}
                                //     {h["params"]['build'] === "first_camp_0" ?
                                //         <image xlinkHref={`/maps/city/city-${h["params"]['level']}.png`} height="15" width="15" x="-7.5" y="-7" />
                                //         : null}
                                //     {this.state.effect === hI ?
                                //         <image className='cons_anim' xlinkHref={`/maps/effect/effect.gif`} height="20" width="20" x="-10" y="-10" />
                                //         : null}
                                //     {this.state.arroundSelect.indexOf(h['coord'][0] + '-' + h['coord'][1]) !== -1 ?
                                //         <Text className="tt-svg">•</Text> : null}
                                // </Hexagon>
                            )}
                        </Layout>
                    </HexGrid>
                    : null}
                {_ID === null ? null :
                    <div id="hover-info-params-anim" className="hover-info-params hover-info-params-animin">
                        <div style={{ display: "inline-flex", width: '100%', color: "#000" }}>
                            <div style={{ width: "40%", fontWeight: "bold" }}>
                                ID
                            </div>
                            <div style={{ width: "60%" }}>
                                {_A[_ID]['id']}
                            </div>
                        </div>
                        <div style={{ display: "inline-flex", width: '100%', color: "#000" }}>
                            <div style={{ width: "40%", fontWeight: "bold" }}>
                                Type
                            </div>
                            <div style={{ width: "60%" }}>
                                {_A[_ID]['params']['type']}
                            </div>
                        </div>
                        {_A[_ID]['params']['build'] ?
                            ['build', 'level', 'pop'].map((li, liI) =>
                                <div key={li + liI} style={{ display: "inline-flex", width: '100%' }}>
                                    <div style={{ width: "40%", fontWeight: "bold", textTransform: "capitalize" }}>
                                        {li}
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {_A[_ID]['params'][li]}
                                    </div>
                                </div>
                            ) : null}
                        {/*JSON.stringify(_A[_ID]['params'])*/}
                        <div onClick={() => this.handleAddCity()} style={{ cursor: "pointer", marginTop: 20, textDecoration: "underline" }}>
                            Add city
                        </div>
                        <div onClick={() => this.getArround(_ID, 1)} style={{ cursor: "pointer", marginTop: 20, textDecoration: "underline" }}>
                            Get arround 1
                        </div>
                        <div onClick={() => this.getArround(_ID, 2)} style={{ cursor: "pointer", marginTop: 20, textDecoration: "underline" }}>
                            Get arround 2
                        </div>
                        <div onClick={() => this.getArround(_ID, 3)} style={{ cursor: "pointer", marginTop: 20, textDecoration: "underline" }}>
                            Get arround 3
                        </div>
                    </div>
                }
                <div onClick={() => { if (this.state.openPanel) this.setState({ openPanel: !this.state.openPanel }) }}
                    className={this.state.openPanel ? 'back-open-b transition-300' : 'back-close transition-300'}
                    style={{ width: this.state.totalWidth, height: this.state.totalHeight, top: 0, left: 0 }}>
                </div>
                <div className={this.state.openPanel ? "panel-choose-open transition-300" : "panel-choose transition-300"} style={{ height: this.state.totalHeight }}>
                    <div onClick={() => this.generate()} className="btn-generate transition-300">
                        RE-GENERATE
                    </div >
                    <div style={{ color: "#000", marginTop: 20, textAlign: "left" }}>
                        <div style={{ cursor: 'pointer' }} onClick={() => this.save()}>SAVE ?</div>
                        {this.state.key === "" ? null :
                            <div onClick={() => this.copyCode()}>
                                key Save :
                                <input type='text' value={this.state.key} id="input-key-save" readOnly disabled style={{ border: 0, width: 300 }} />
                            </div>
                        }
                        <div>
                            <div>
                                Load a key
                            </div>
                            <div style={{ display: 'inline-flex' }}>
                                <input type='text' id="input-key" />
                                <div style={{ cursor: "pointer", marginLeft: 15 }} onClick={() => this.loadKey()}>Load {">"}</div>
                            </div>
                        </div>
                    </div>

                    <div className='close-btn-choose' onClick={() => this.setState({ openPanel: !this.state.openPanel })}>
                        CLOSE
                    </div>
                </div>
            </div >
        )
    }
}

export default WorldGame2;
