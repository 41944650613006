
import React, { Component } from 'react';

// https://www.artstation.com/search?q=scifi&sort_by=relevance
// https://www.pinterest.com.au/pin/439523244864754448/

class EditorFile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: { "id": "", "topic": [], "image": "", "name": "", "short": "", "content": [], "draft": true },
            topics: [],
            sureDel: null,
            dark: "darkmode"
        }
    }

    componentDidMount() {
        var id = this.props.id
        var files_01 = require('./utils/files/files_01.json');
        var topics = []
        files_01.forEach(ele => {
            ele['topic'].forEach(t => {
                topics.push({ 'topic': t, 'select': false })
            })
        })
        var last = files_01[files_01.length - 1]
        var new_id = parseInt(last['id'].substring(1, 5)) + 1
        new_id = "f0000".substring(0, 5 - new_id.toString().length) + new_id.toString()
        var file = {
            "id": new_id,
            'image': `/files/file-${new_id}.jpg`,
            "topic": [],
            "name": "",
            "short": "",
            "content": [],
            "draft": true
        }
        if (id !== undefined) {
            files_01.forEach(ele => {
                if (ele['id'] === id) {
                    file = ele
                    ele['topic'].forEach(t => {
                        topics.forEach((tt, ttI) => {
                            if (tt['topic'] === t) topics[ttI]['select'] = true
                        })
                    })
                }
            })
        }
        this.setState({ file: file, topics: topics })
    }

    selectTopic(index) {
        var topics = this.state.topics
        topics[index]['select'] = !topics[index]['select']
        this.setState({ topics: topics })
    }

    addTag() {
        var tag = document.getElementById('new-tag').value
        if (tag !== "") {
            console.log(tag)
            var topics = this.state.topics
            topics.push({ 'topic': tag.toLowerCase(), 'select': false })
            this.setState({ topics: topics })
            document.getElementById('new-tag').value = ""
        }
    }

    handleVal(type, index, val) {
        var file = this.state.file
        if (type === 'content') {
            file['content'][index] = val
        }
        else file[type] = val
        this.setState({ file: file })
    }

    addPara() {
        var file = this.state.file
        file['content'].push("")
        this.setState({ file: file })
    }

    dump() {
        var f = JSON.parse(JSON.stringify(this.state.file))
        f['topic'] = []
        this.state.topics.forEach(ele => {
            if (ele['select'] === true) f['topic'].push(ele['topic'])
        })
        f['content'].forEach((ele, eleI) => {
            f['content'][eleI] = ele.replace(/<b>/g, "<span class='bold'>").replace(/<\/b>/g, "</span>")
            f['content'][eleI] = ele.replace(/<b>/g, "<span class='italic'>").replace(/<\/i>/g, "</span>")
        })
        f['short'] = f['short'].replace(/<b>/g, "<span class='bold'>").replace(/<\/b>/g, "</span>")
        f['short'] = f['short'].replace(/<i>/g, "<span class='italic'>").replace(/<\/i>/g, "</span>")
        console.log(JSON.stringify(f, null, 4))
    }

    deletePara(index) {
        var file = this.state.file
        file['content'].splice(index, 1)
        this.setState({ file: file, sureDel: null })
    }

    render() {
        return (
            <div className={"transition-300 " + this.state.dark} style={{ minHeight: window.innerHeight, backgroundColor: this.state.dark === "darkmode" ? '#1e1e1e' : "#fafafa", paddingBottom: 100 }}>
                <div className="container" style={{ position: "relative" }}>
                    <img alt="quit_onboard" src="/quit_onboard.svg" className="close-btn-work" style={{ left: 30, height: 13, top: 24, position: 'absolute' }} onClick={() => window.location = '/'} />
                    <img alt="darkmode" src={this.state.dark === "darkmode" ? "/civ/sun.png" : '/civ/moon.png'} className="close-btn-work" style={{ right: 30, height: 20, top: 24, position: 'absolute' }}
                        onClick={() => this.setState({ dark: this.state.dark === "darkmode" ? "whitemode" : "darkmode" })} />
                    <div className="text-center" style={{ fontSize: 20, paddingTop: 20, fontFamily: "Meri" }}>
                        Editor Files
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding" style={{ marginTop: 35 }}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding">
                            <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 " + this.state.dark}>
                                <div className="label-editor">
                                    Title files
                                </div>
                                <input className="input-editor" type='text' id='title' onChange={(e) => this.handleVal('name', null, e.target.value)}
                                    value={this.state.file['name']} placeholder="Title" />
                            </div>
                            <div className={"col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 img-hover " + this.state.dark} style={{ position: "relative" }}>
                                <div className="label-editor">
                                    Banner name
                                </div>
                                <input disabled className="input-editor" type='text' id='banner' value={this.state.file['image']} />
                                <img alt='banner' className="img-banner-ex" src={this.state.file['image']} />
                            </div>
                        </div>
                        <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " + this.state.dark} style={{ marginTop: 40 }}>
                            <div className="label-editor">
                                Short desc
                            </div>
                            <textarea rows="3" className="input-editor" id='short' onChange={(e) => this.handleVal('short', null, e.target.value)}
                                value={this.state.file['short']} placeholder='Short description of the story' />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: 40 }}>
                            <div className="label-editor">
                                Topics
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding" style={{ display: "inline-block" }}>
                                {this.state.topics.map((t, tI) =>
                                    <div onClick={() => this.selectTopic(tI)} className={t['select'] === false ? "topic-tag transition-300" : "topic-tag topic-tag-select transition-300"} key={'topic_' + tI}>
                                        {t['topic']}
                                        <img alt='plus' className="transition-300" src={t['select'] === false ? "/plus_b.png" : "/plus_w.png"} style={t['select'] === false ? { height: 18, marginTop: -2, marginLeft: 4 } : { height: 18, marginTop: -2, marginLeft: 4, transform: "rotate(45deg)" }} />
                                    </div>
                                )}
                                <div className="topic-tag- transition-300">
                                    <input type='text' style={{ border: 0, backgroundColor: "transparent" }} placeholder="Add tag" id='new-tag' />
                                    <img alt='plus' onClick={() => this.addTag()} className="transition-300" src="/plus_b.png" style={{ height: 18, marginTop: -2, marginLeft: 4 }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style={{ marginTop: 40 }}>
                            <div className="label-editor">
                                Content
                            </div>
                            {this.state.file['content'].map((c, cI) =>
                                <div key={'content_' + cI} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding">
                                    <div className="label-editor" style={{ marginLeft: 10, fontSize: 14, position: "relative" }}>
                                        Paragraph n°{cI + 1}
                                        {this.state.sureDel === cI ?
                                            <img alt="validdel" onClick={() => this.deletePara(cI)} style={{ cursor: 'pointer', position: "absolute", right: 0, top: 0, height: 26 }} src="/civ/valid_tick.png" />
                                            :
                                            <img alt="del" onClick={() => {
                                                this.setState({ sureDel: cI });
                                                setTimeout(function () {
                                                    this.setState({ sureDel: null });
                                                }.bind(this), 2000)
                                            }}
                                                style={{ cursor: 'pointer', position: "absolute", right: 5, top: 5, height: 12 }} src="/quit_onboard.svg" />
                                        }
                                    </div>
                                    <textarea rows="6" className="input-editor" onChange={(e) => this.handleVal('content', cI, e.target.value)}
                                        value={c} id={"content-" + cI} placeholder={'Paragraph n°' + (cI + 1)} />
                                </div>
                            )}
                            <div onClick={() => this.addPara()} className="content-add">
                                + Add paragraph
                            </div>
                        </div>
                        <div onClick={() => this.dump()} className="content-add">
                            Dump json (in console)
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default EditorFile;
