/*
    cost:       INT
                Cost to build this building in GOD POINT

    gp:         ARRAY: FLOAT ou INT ?
                0: multiplicateur de pop actuel
                1: bonus point

    base_pop:   INT
                Min base Population on build

    coef_evolve_pop:    INT
                        Coef to calculate pop evolution
                        coef_evolve_pop - actual_pop - richesse + ruin in a math random

    min_evolve_pop:     INT
                        Min necessary actual_pop to have the chance to level up the building

    max_pop:    INT
                Max pop availble spot for this level (block evolution of population number after reaching it)

    coef_evolve_build:  INT
                        Coef to calculate building evolution int next level or next phase
                        coef_evolve_build - actual_pop - richesse + ruin
                        OU:
                        coef_evolve_build + (pop_max - actual_pop) - richesse + ruin

    ruin:       INT
                Ruin level, when your builds start to become bad

    coef_ruin:  INT
                Losing points per season: coef_ruin * ruin

    wealth:     INT
                Wealth level of your builds

    coef_wealth:    INT
                    Winning points per season: coef_wealth * wealth


*/

var builds = {
    "camp_1": {
        "name": "Small camp",
        "level": {
            "1": {
                "cost": 3,
                "gp_1": 0.8,
                "gp_2": 1,
                "pop": 3,
                "base_pop": 3,
                "coef_evolve_pop": 9,
                "min_evolve_pop": 8,
                "max_pop": 16,
                "coef_evolve_build": 20,
                "ruin": 0,
                "coef_ruin": 2,
                "wealth": 0,
                "coef_wealth": 2
            },
            "2": {
                "cost": 5,
                "gp_1": 0.95,
                "gp_2": 2,
                "pop": 8,
                "base_pop": 8,
                "coef_evolve_pop": 24,
                "min_evolve_pop": 20,
                "max_pop": 40,
                "coef_evolve_build": 40,
                "ruin": 0,
                "coef_ruin": 2.5,
                "wealth": 0,
                "coef_wealth": 2.5
            },
            "3": {
                "cost": 8,
                "gp_1": 1.15,
                "gp_2": 4,
                "pop": 20,
                "base_pop": 20,
                "coef_evolve_pop": 60,
                "min_evolve_pop": 46,
                "max_pop": 92,
                "coef_evolve_build": 60,
                "ruin": 0,
                "coef_ruin": 3,
                "wealth": 0,
                "coef_wealth": 3
            }
        }
    },
    "camp_2": {
        "name": "Medium camp",
        "level": {
            "1": {
                "cost": 13,
                "gp_1": 1.45,
                "gp_2": 8,
                "pop": 46,
                "base_pop": 46,
                "coef_evolve_pop": 138,
                "min_evolve_pop": 94,
                "max_pop": 188,
                "coef_evolve_build": 100,
                "ruin": 0,
                "coef_ruin": 4.5,
                "wealth": 1,
                "coef_wealth": 4.5
            },
            "2": {
                "cost": 25,
                "gp_1": 1.8,
                "gp_2": 16,
                "pop": 94,
                "base_pop": 94,
                "coef_evolve_pop": 282,
                "min_evolve_pop": 192,
                "max_pop": 384,
                "coef_evolve_build": 200,
                "ruin": 0,
                "coef_ruin": 5.75,
                "wealth": 1,
                "coef_wealth": 5.5
            },
            "3": {
                "cost": 48,
                "gp_1": 2.2,
                "gp_2": 32,
                "pop": 192,
                "base_pop": 192,
                "coef_evolve_pop": 576,
                "min_evolve_pop": 390,
                "max_pop": 780,
                "coef_evolve_build": 400,
                "ruin": 0,
                "coef_ruin": 7,
                "wealth": 2,
                "coef_wealth": 6.5
            }
        }
    }
}


var defaultColors = {
    AAA: '#e9ecef',
    AA: '#adb5bd',
    A: '#ccc15f',
    B: '#bcb954',
    C: '#a5a850',
    D: '#92a54f',
    E: '#86a049',
    F: '#4d68af',
    G: '#3b559b',
    H: '#293f77',
    I: '#24386b',
    J: '#233668',
    K: '#213466',
    L: '#1d2e5b'
}

var defaultColorsType = {
    "volcano": '#e63946',
    "high_mountain": '#e9ecef',
    "mountain": '#adb5bd',
    "low_mountain": '#ccc15f',
    "high_hill": '#bcb954',
    "hill": '#a5a850',
    "plain": '#92a54f',
    "low_plain": '#86a049',
    "beach_coast": '#4d68af',
    "front_coast": '#3b559b',
    "mid_coast": '#293f77',
    "deep_coast": '#24386b',
    "front_ocean": '#233668',
    "mid_ocean": '#213466',
    "deep_ocean": '#1d2e5b'
}

var defaultType = {
    AAA: 'high_mountain',
    AA: 'mountain',
    A: 'low_mountain',
    B: 'high_hill',
    C: 'hill',
    D: 'plain',
    E: 'low_plain',
    F: 'beach_coast',
    G: 'front_coast',
    H: 'mid_coast',
    I: 'deep_coast',
    J: 'front_ocean',
    K: 'mid_ocean',
    L: 'deep_ocean'
}

function invertHex(hex) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}

export { builds, defaultColors, defaultColorsType, defaultType, invertHex }
