import React from 'react';
import ReactDOM from 'react-dom';
import './utils/style/fonts.css';
import './utils/style/index.css';
import './utils/style/quests.css';
import './utils/style/tiles.css';
import './utils/style/civ.css';
import './utils/style/fire.css';
import './utils/style/files.css';
import './utils/style/maps.css';
import './utils/style/cities.css';
import './utils/style/eventCard.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
