
import React, { Component } from 'react';

class Simu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            started: false,
            moving: false,
            blobs: 0
        }
        this.xVal = []
        this.yVal = []
        var lenTotal = 40
        for (var i = 0; i < lenTotal; i++) {
            this.xVal.push(i)
            this.yVal.push(i)
        }
        this.plat = null
        this.blob = {}
    }

    componentDidMount() {

    }

    generateToken(length) {
        var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        var b = [];
        for (var i = 0; i < length; i++) {
            var j = (Math.random() * (a.length - 1)).toFixed(0);
            b[i] = a[j];
        }
        var tok = b.join("")
        return tok;
    }

    createNewBlob() {
        if (this.state.blobs > 5) {
            console.log('Too much blob')
        }
        else {
            this.setState({ blobs: this.state.blobs + 1 })
            var is_water = true
            while (is_water) {
                var posi = this.simuPosi(this.xVal.length)
                var val = this.xVal.length * posi[1] + posi[0]
                if (this.plat[val]['type'] !== 'water') is_water = false
            }
            var tok_ = this.generateToken(15)
            var n_b = {
                'coord': posi,
                'lifetime': (Math.random() * (2500 - 1000) + 1000).toFixed(),
                "id_blob": tok_
            }
            this.blob[tok_] = n_b
            this.moveBlob(tok_)
            this.setState({ r: 1 })
        }
    }

    moveBlob(tok_) {
        setTimeout(function () {
            var woot = Math.floor(Math.random() * Math.floor(3))
            var is_good = true
            var old_coord = JSON.parse(JSON.stringify(this.blob[tok_]['coord']))
            var lenTot = this.xVal.length * (this.yVal.length - 1) + (this.xVal.length - 1)
            console.log(lenTot)
            while (is_good) {
                if (woot === 0) old_coord[0] = old_coord[0] + 1
                if (woot === 1) old_coord[0] = old_coord[0] - 1
                if (woot === 2) old_coord[1] = old_coord[1] + 1
                if (woot === 3) old_coord[1] = old_coord[1] - 1
                var val_coo = this.xVal.length * old_coord[1] + old_coord[0]
                console.log(val_coo)
                if (val_coo >= 0 && val_coo <= lenTot) is_good = false
            }
            this.blob[tok_]['coord'] = old_coord
            this.blob[tok_]['lifetime'] = this.blob[tok_]['lifetime'] - 1
            this.setState({ r: 1 })
            if (this.blob[tok_]['lifetime'] > 0) this.moveBlob(tok_)
        }.bind(this), 500)
    }

    simuPosi(len) {
        return [Math.floor(Math.random() * Math.floor(len - 1)), Math.floor(Math.random() * Math.floor(len))]
    }

    placeItem() {
        this.plat = {}
        for (var x = 0; x < this.xVal.length * this.yVal.length; x++) {
            this.plat[x] = {
                'type': null,
                'content': null
            }
        }
        for (x = 0; x < 500; x++) {
            var posi = this.simuPosi(this.xVal.length)
            var val = this.xVal.length * posi[1] + posi[0]
            this.plat[val]['type'] = 'plains'
        }
        for (x = 0; x < 50; x++) {
            posi = this.simuPosi(this.xVal.length)
            val = this.xVal.length * posi[1] + posi[0]
            this.plat[val]['type'] = 'forest'
            var randN = Math.floor(Math.random() * Math.floor(4))
            if (randN === 0 && this.plat[val + 1]) this.plat[val + 1]['type'] = 'forest'
            if (randN === 1 && this.plat[val + this.xVal.length]) this.plat[val + this.xVal.length]['type'] = 'forest'
            if (randN === 2 && this.plat[val - 1]) this.plat[val - 1]['type'] = 'forest'
            if (randN === 3 && this.plat[val - this.xVal.length]) this.plat[val - this.xVal.length]['type'] = 'forest'
            randN = Math.floor(Math.random() * Math.floor(4))
            if (randN === 0 && this.plat[val + 1]) this.plat[val + 1]['type'] = 'forest'
            if (randN === 1 && this.plat[val + this.xVal.length]) this.plat[val + this.xVal.length]['type'] = 'forest'
            if (randN === 2 && this.plat[val - 1]) this.plat[val - 1]['type'] = 'forest'
            if (randN === 3 && this.plat[val - this.xVal.length]) this.plat[val - this.xVal.length]['type'] = 'forest'
        }
        for (x = 0; x < 30; x++) {
            posi = this.simuPosi(this.xVal.length)
            val = this.xVal.length * posi[1] + posi[0]
            this.plat[val]['type'] = 'water'
            randN = Math.floor(Math.random() * Math.floor(4))
            if (randN === 0 && this.plat[val + 1]) this.plat[val + 1]['type'] = 'water'
            if (randN === 1 && this.plat[val + this.xVal.length]) this.plat[val + this.xVal.length]['type'] = 'water'
            if (randN === 2 && this.plat[val - 1]) this.plat[val - 1]['type'] = 'water'
            if (randN === 3 && this.plat[val - this.xVal.length]) this.plat[val - this.xVal.length]['type'] = 'water'
        }

        for (x = 0; x < this.xVal.length * this.yVal.length; x++) {
            if (this.plat[x]['type'] === null) {
                var test_water = [
                    x - this.xVal.length, x + this.xVal.length, x - 1, x + 1, x - this.xVal.length - 1,
                    x + this.xVal.length + 1, x - this.xVal.length + 1, x + this.xVal.length - 1
                ]
                var is_water_near = 0
                var is_forest_near = 0
                test_water.forEach(ele => {
                    if (this.plat[ele] && this.plat[ele]['type'] === 'water') {
                        is_water_near = is_water_near + 1
                    }
                    if (this.plat[ele] && this.plat[ele]['type'] === 'forest') {
                        is_forest_near = is_forest_near + 1
                    }
                })
                var rand = null
                if (is_forest_near >= 4) this.plat[x]['type'] = 'forest'
                else if (is_forest_near >= 2) {
                    rand = Math.floor(Math.random() * Math.floor(5 - is_forest_near))
                    if (rand === 0) this.plat[x]['type'] = 'forest'
                    else this.plat[x]['type'] = 'plains'

                }
                else if (is_water_near >= 4) this.plat[x]['type'] = 'water'
                else if (is_water_near >= 2) {
                    rand = Math.floor(Math.random() * Math.floor(5 - is_water_near))
                    if (rand === 0) this.plat[x]['type'] = 'water'
                    else this.plat[x]['type'] = 'plains'

                }
                else this.plat[x]['type'] = 'plains'
            }
        }
        this.setState({ started: true })
    }


    render() {
        var size = 20
        // var color = ["rgba(255, 255, 255)", "rgb(64, 212, 51)", "rgb(138, 245, 66)", "rgb(185, 212, 51)", "rgb(212, 177, 51)", "rgb(212, 129, 51)", "rgb(212, 86, 51)"]
        var colorMap = {
            'forest': "#79c389",
            "water": "#8aa6c5",
            'plains': "#ccec9e",
            "moutains": "#929292"
        }
        return (
            <div style={{ width: "100%", border: "0px solid", position: "relative" }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="btn-top" onClick={() => this.placeItem()}>
                        {this.state.started ? "Started" : "Start"}
                    </div>
                    {this.state.started ?
                        <div className="btn-top" onClick={() => this.createNewBlob()}>
                            Add Blob
                    </div>
                        : null}
                </div>
                {this.plat === null ? null :
                    <div className="map-params" style={{ width: this.xVal.length * size + 10, height: this.yVal.length * size + 10 }}>
                        {/* <div className='transition-1500' style={{
                            zIndex: 1, height: 6, width: 6, borderRadius: 6, backgroundColor: "red",
                        top: this.perso[1] * size + 14, left: this.perso[0] * size + 10, position: "absolute"
                        }}></div> */}
                        {Object.keys(this.blob).map((ele, eleI) =>
                            <div key={ele + eleI} className='transition-1500' style={{
                                zIndex: 2, height: 6, width: 6, borderRadius: 6, backgroundColor: "red", cursor: 'pointer',
                                top: this.blob[ele]["coord"][1] * size + 10, left: this.blob[ele]["coord"][0] * size + 8, position: "absolute"
                            }}></div>
                        )}
                        {this.xVal.map((x, xI) =>
                            this.yVal.map((y, yI) =>
                                <div className="unique-map-area" key={"item_" + xI + yI + x + y} style={{
                                    top: yI * size + 2, left: xI * size + 2, height: size - 2, width: size - 2,
                                    backgroundColor: colorMap[this.plat[this.xVal.length * yI + xI]['type']] ? colorMap[this.plat[this.xVal.length * yI + xI]['type']] : "#ddd",
                                    // backgroundColor: color[this.plat[this.xVal.length * yI + xI]] ? color[this.plat[this.xVal.length * yI + xI]] : "#ddd"
                                }}>
                                    <span className='hover-unique-area'>
                                        {JSON.stringify(this.plat[this.xVal.length * yI + xI])}
                                        <br />
                                        {' x: ' + xI + " ; y: " + yI}{' (' + (this.xVal.length * yI + xI) + ')'}
                                        {Object.keys(this.blob).map((ele, eleI) =>
                                            this.blob[ele]['coord'][0] === xI && this.blob[ele]['coord'][1] === yI ?
                                                <div>
                                                    {this.blob[ele]['lifetime']} - {this.blob[ele]['id_blob']}
                                                </div>
                                                : null)}
                                    </span>
                                </div>
                            ))}
                    </div>
                }
            </div>
        )
    }

}

export default Simu;
