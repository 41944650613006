
import React, { Component } from 'react';
import { fetchRoom, doAction } from './QuestsUtils'

class Quests extends Component {

    constructor() {
        super()
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            progress: 0,
            selectInfo: null,
            indexInventory: null,
            inventory: [
            ],
            indexHint: null,
            hints: [
                // {
                //     'name': 'Hint 1',
                //     '_id': "hint_1",
                // }
            ],
            indexRoom: 0,
            rooms: fetchRoom(['room_1'])
        }
        this.noClick = false
    }

    componentDidMount() {
        setTimeout(function () {
            this.addElement("You wake up in a room. You don't reconize this place. You want to get out...", false)
        }.bind(this), 1000)
    }

    handleAction(value, type, index) {
        if (!this.noClick) {
            this.noClick = true
            this.selectSmgth(value, type, index)
            setTimeout(function () {
                this.noClick = false
            }.bind(this), 1000)
        }
    }

    activeGood(room, index) {
        var isOk = false
        if (room["active"] === true) isOk = true
        else if (room["active_needed"].length === 0) isOk = true
        else {
            if (this.state.indexInventory !== null) {
                if (room['active_needed'].indexOf(this.state.inventory[this.state.indexInventory]['_id']) !== -1) isOk = true
            }
            if (this.state.indexHint !== null) {
                if (room['active_needed'].indexOf(this.state.hints[this.state.indexHint]['_id']) !== -1) isOk = true
            }
            var rooms = this.state.rooms
            if (isOk) {
                rooms[index]['active'] = isOk
                this.setState({ rooms: rooms, indexRoom: index })
                this.addElement('You unlocked ' + room.name)
            }
            else {
                this.addElement("You can't enter, you may need something to enter this room...", false)
            }
        }
    }

    selectSmgth(value, type, index) {
        var rooms = null
        var isOk = false
        if (type === 'room') {
            rooms = this.state.rooms
            if (rooms[index]['thing_needed'].length > 0) {
                isOk = false
                if (this.state.indexInventory !== null) {
                    if (rooms[index]['thing_needed'].indexOf(this.state.inventory[this.state.indexInventory]['_id']) !== -1) isOk = true
                }
                if (this.state.indexHint !== null) {
                    if (rooms[index]['thing_needed'].indexOf(this.state.hints[this.state.indexHint]['_id']) !== -1) isOk = true
                }
                rooms[index]['show_action'] = isOk
                this.setState({ rooms: rooms })
            }
            this.setState({ indexRoom: index })
            this.addElement('You enter in ' + value.name, false)
        }
        if (type === 'item') {
            rooms = this.state.rooms
            if (rooms[this.state.indexRoom]['thing_needed'].length > 0) {
                isOk = false
                if (index !== null) {
                    if (rooms[this.state.indexRoom]['thing_needed'].indexOf(this.state.inventory[index]['_id']) !== -1) isOk = true
                }
                rooms[this.state.indexRoom]['show_action'] = isOk
                this.setState({ rooms: rooms })
            }
            this.setState({ indexInventory: index })
            if (value === null) this.addElement('You have nothing in your hands', false)
            else this.addElement('You take ' + value.name + ' in your hands', false)
        }
        if (type === 'action') {
            rooms = this.state.rooms
            var indexRoom = this.state.indexRoom
            var inventory = this.state.inventory
            var hints = this.state.hints
            var new_content = doAction(value, rooms, inventory, hints, indexRoom)
            this.setState({ rooms: new_content['rooms'], inventory: new_content['inventory'], hints: new_content['hints'], indexRoom: new_content['indexRoom'] })
            this.addElement(new_content['descAction'], true)
        }
    }

    addElementHistory(txt, multi) {
        if (!multi) txt = [txt]
        txt.forEach(ele => {
            var id = Math.random()
            var d = new Date()
            var h = d.toString().split(' ')[4]
            // var txtDate = "[" + h + "] " + ele
            var newDiv = document.createElement("div", { 'id': id });

            var divDate = document.createElement("div");
            var newContentDate = document.createTextNode("[" + h + "]");
            divDate.appendChild(newContentDate);

            var divDesc = document.createElement("div");
            divDesc.classList.add("history-quests-desc")
            var newContentDesc = document.createTextNode(ele);
            divDesc.appendChild(newContentDesc);

            // var newContent = document.createTextNode(txtDate);
            // newDiv.appendChild(newContent);
            newDiv.appendChild(divDate);
            newDiv.appendChild(divDesc);
            newDiv.classList.add("history-quests")
            document.getElementById('history-div').prepend(newDiv);
        });
    }

    addElement(txt, multi) {
        this.addElementHistory(txt, multi)
        // POP UP BLACK BOTTOM
        // var txtLen = 0
        // var id = Math.random()
        // var newDiv = document.createElement("div", { 'id': id });
        // if (multi) {
        //     txt.forEach(ele => {
        //         var tmpDiv = document.createElement("div");
        //         newContent = document.createTextNode(ele);
        //         tmpDiv.appendChild(newContent);
        //         newDiv.appendChild(tmpDiv);
        //         txtLen = txtLen + ele.length
        //     });
        // }
        // else {
        //     var newContent = document.createTextNode(txt);
        //     newDiv.appendChild(newContent);
        //     txtLen = txt.length
        // }
        // newDiv.classList.add("popup-quests")
        // document.body.appendChild(newDiv);
        // var timeo = 2500
        // if (txtLen > 50) timeo = 5000
        // else if (txtLen > 30) timeo = 3500
        // setTimeout(function () {
        //     newDiv.classList.add("popup-quests-disappear")
        // }.bind(this), timeo)
        // setTimeout(function () {
        //     newDiv.remove()
        // }.bind(this), timeo + 1000)
    }

    render() {
        return (
            <div style={{ width: "100%" }} className='unselect-text'>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center header-quests" style={{}}>
                    {/* QUESTS – {this.state.progress}% */}
                    <div className="wrapper-glitch">
                        <div className="glitch-effect">QUESTS – {this.state.progress}%</div>
                        <div className="glitch-effect">QUESTS – {this.state.progress}%</div>
                        <div className="glitch-effect">QUESTS – {this.state.progress}%</div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding" style={{ minHeight: this.state.totalHeight - 72 }}>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 no-padding" style={{ borderRight: "1px solid #ccc" }}>
                        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding">
                            <div className="title-area">
                                AVAILABLE
                            </div>
                        </div> */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding">
                            <div className="sub-title-area">
                                ROOMS
                            </div>
                        </div>
                        {this.state.rooms.map((room, roomI) =>
                            <div onClick={() => room.active ? this.selectSmgth(room, 'room', roomI) : this.activeGood(room, roomI)} key={"room_" + roomI} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                                <div className={this.state.indexRoom === roomI ? "transition-300 select-title-room showListAnim" : "transition-300 title-room showListAnim"}>
                                    <span className={room.active ? '' : 'cant-access'}>{room.name}{room.active ? null : <span> – Locked</span>}</span>
                                </div>
                            </div>
                        )}
                        {this.state.inventory.length === 0 ? null :
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding" style={{ marginTop: 35 }}>
                                <div className="sub-title-area">
                                    INVENTORY
                                </div>
                            </div>
                        }
                        {this.state.inventory.length === 0 ? null : this.state.inventory.map((item, itemI) =>
                            <div onClick={() => this.state.indexInventory === itemI ? this.selectSmgth(null, 'item', null) : this.selectSmgth(item, 'item', itemI)}
                                key={"item_" + itemI} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                                <div className={this.state.indexInventory === itemI ? "transition-300 select-title-room showListAnim" : "transition-300 title-room showListAnim"}>
                                    <span>{item.name}</span>
                                </div>
                            </div>
                        )}
                        {this.state.hints.length === 0 ? null :
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding" style={{ marginTop: 35 }}>
                                <div className="sub-title-area">
                                    Hints
                                </div>
                            </div>
                        }
                        {this.state.hints.length === 0 ? null : this.state.hints.map((hint, hintI) =>
                            <div onClick={() => this.state.indexHint === hintI ? this.setState({ indexHint: null }) : this.setState({ indexHint: hintI })}
                                key={"hint_" + hintI} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                                <div className={this.state.indexHint === hintI ? "transition-300 select-title-room showListAnim" : "transition-300 title-room showListAnim"}>
                                    <span>{hint.name}</span>
                                </div>
                            </div>
                        )}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding" style={{ marginTop: 35 }}>
                            <div className="sub-title-area" style={{ marginBottom: 0 }}>
                                History
                                </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 scroll-small-black" style={{ overflowY: "scroll", padding: "15px 0", height: 200, borderBottom: "1px solid #ccc" }} id='history-div' >
                        </div>
                        <div style={{ height: 35 }}></div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12 no-padding">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding">
                            <div className="sub-title-area">
                                ACTIONS{this.state.rooms[this.state.indexRoom] ? ' – ' + this.state.rooms[this.state.indexRoom]['name'] : null}
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row justify-content-md-center no-padding" style={{ marginTop: 20, position: 'relative' }}>
                            <div className="opacity-effect"></div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 room-desc">
                                {this.state.rooms[this.state.indexRoom]['actual_desc']}
                            </div>
                            {this.state.rooms[this.state.indexRoom]['actions'].length === 0 ?
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 nothing-to-do">
                                    Nothing to do here right now, maybe later...
                                </div>
                                : !this.state.rooms[this.state.indexRoom]['show_action'] ? null
                                    : this.state.rooms[this.state.indexRoom]['actions'].map((action, actionI) =>
                                        <div key={'action_' + actionI} className="col-xl-3 col-lg-3 col-md-5 col-sm-6 col-6" onClick={() => this.handleAction(action['_id'], 'action', actionI)}>
                                            <div className='action-card transition-300'>
                                                <div className='action-card-title'>
                                                    {action.title}
                                                </div>
                                                <div className='action-card-desc'>
                                                    {action.desc}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Quests
