
import React, { Component } from 'react';

// https://motsavec.fr/5-lettres/dictionnaire/mots-courants/5
var data = [
    "comme", "entre", "apres", "aussi", "alors", "ainsi", "ville", "trois", "monde", "annee", "titre", "avant", "deces", "serie", "avoir", "temps", "place", "debut", "refus",
    "autre", "suite", "faire", "cours", "album", "ligne", "moins", "homme", "forme", "grand", "selon", "genre", "coupe", "grand", "scene", "nomme", "celle", "celui", "ordre",
    "armee", "reste", "droit", "point", "femme", "siege", "effet", "match", "fille", "avril", "liste", "jeune", "texte", "route", "maire", "poste", "corps", "parti", "grace",
    "roman", "ecole", "connu", "elles", "ecrit", "porte", "image", "ouest", "livre", "style", "union", "terme", "petit", "ligue", "passe", "frere", "donne", "duree", "comte",
    "parmi", "objet", "cadre", "musee", "cause", "comte", "quand", "abord", "seule", "terre", "video", "donne", "libre", "ecole", "russe", "stade", "rouge", "poids", "total",
    "label", "blanc", "score", "saint", "large", "façon", "salle", "etude", "bande", "assez", "aucun", "musee", "belge", "noeud", "signe", "piece", "stade", "prise", "force",
    "passe", "hiver", "sujet", "issue", "choix", "sante", "acces", "amour", "carte", "coeur", "basse", "moyen", "unite", "terre", "revue", "heure", "ouest", "haute", "forte",
    "piano", "zebre", "soeur", "enfin", "phase", "rugby", "glace", "court", "etats", "latin", "suivi", "appel", "etape", "jouer", "creer", "usage", "coupe", "offre", "dinde",
    "radio", "local", "hotel", "outre", "mieux", "chant", "forêt", "draft", "train", "lutte", "regne", "eleve", "poete", "quant", "garde", "masse", "forme", "lycee", "aller",
    "ville", "futur", "fonds", "piste", "lance", "juste", "champ", "crise", "reine", "tombe", "croix", "theme", "tente", "huile", "doute", "sorte", "vente", "gmina", "plein",
    "gagne", "chute", "eleve", "parce", "avion", "peine", "sport", "perdu", "culte", "arabe", "usine", "mixte", "douze", "voire", "noire", "canal", "arrêt", "ferme", "heros",
    "lance", "danse", "jaune", "notre", "fonde", "these", "agent", "front", "final", "royal", "vieux", "porte", "votre", "toile", "serbe", "riche", "homme", "juifs", "actif",
    "croix", "metro", "somme", "vingt", "aider", "venir", "vivre", "grade", "prive", "baron", "promu", "cycle", "essai", "angle", "force", "chose", "etage", "table", "vaste",
    "recit", "ligue", "prevu", "matin", "rendu", "opera", "tiers", "trone", "echec", "perte", "union", "salon", "edite", "belle", "bilan", "arbre", "civil", "pêche", "vendu",
    "ocean", "bourg", "marie", "phare", "oncle", "black", "senat", "battu", "drame", "mille", "quart", "ecran", "comte", "orgue", "debit", "front", "canon", "noter", "menee",
    "poeme", "regle", "photo", "tenue", "boîte", "gamme", "metal", "puits", "appui", "verre", "peint", "poule", "chien", "frais", "acide", "tenir", "subit", "rayon", "ligne",
    "elite", "debat", "epoux", "duche", "noirs", "start", "neveu", "genie", "opera", "sable", "rouge", "plage", "salon", "noble", "villa", "queue", "blanc", "mener", "veuve",
    "degre", "cesse", "monte", "depot", "acier", "decor", "ferme", "corps", "carre", "crime", "norme", "lundi", "garde", "virus", "achat", "tribu", "alias", "biais", "fuite",
    "ecart", "clair", "froid", "faute", "grave", "admis", "payer", "verte", "neige", "cible", "belle", "seize", "trace", "metal", "reine", "lourd", "citer", "porte", "amour",
    "dette", "sacre", "manga", "leger", "balle", "anime", "noyau", "ombre", "egard", "coule", "rugby", "pluie", "voile", "bible", "barre", "amont", "tirer", "guide", "beton",
    "utile", "trace", "outil", "canal", "ajout", "moine", "vague", "depit", "major", "cadet", "greve", "rural", "motif", "geant", "desir", "trait", "poche", "foyer", "nobel",
    "pomme", "agile", "cuire", "olive", "lapin", "amene", "vertu", "sucre", "foule", "fruit", "tache", "repos", "autel", "voute", "bruit", "alpin"
]

const alphabet = ["a", "z", "e", "r", "t", "y", "u", "i", "o", "p", "q", "s", "d", "f", "g", "h", "j", "k", "l", "m", "w", "x", "c", "v", "b", "n"]

class Word extends Component {

    constructor(props) {
        super(props);
        this.state = {
            word: "",
            guess: "",
            show: false,
            guesses: [],
            score: [0, 0],
            best: [0, 0],
            err: null,
            end: false
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyEvent.bind(this));
        this.newword()
        document.getElementById('inputRef').focus()
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyEvent.bind(this));
    }

    setErr(err, more = 0) {
        this.setState({ err: err })
        setTimeout(function () {
            this.setState({ err: null })
        }.bind(this), 1500 + more)
    }

    keyEvent(e) {
        var letter = e.key.toLowerCase().replace('key', "")
        var guess = this.state.guess, word = this.state.word
        if (letter === "enter" && this.state.end === true) {
            if (this.state.guesses.length === 8) this.setState({ score: [0, 0] })
            this.setState({ end: false, guess: "", guesses: [], word: data[Math.floor(Math.random() * data.length)] })
        }
        else if (letter === "enter") {
            if (guess.length < 5) this.setErr('Le mot est incomplet')
            else {
                if (data.indexOf(guess) === -1) this.setErr("Le mot n'est pas dans la liste")
                else {
                    var tmp = [], good = 0
                    guess.split("").forEach((txt, txtI) => {
                        if (txt === word[txtI]) {
                            tmp.push([txt, 2])
                            good = good + 1
                        }
                        else if (word.indexOf(txt) !== -1) tmp.push([txt, 1])
                        else tmp.push([txt, 0])
                    });
                    var GS_ = this.state.guesses
                    GS_.push(tmp)
                    if (good === 5) {
                        this.setState({ GS_: tmp, guess: "", end: true })
                        setTimeout(function () { this.setScore() }.bind(this), 20)
                    }
                    else {
                        if (GS_.length === 8) {
                            this.setState({ end: true })
                            this.setErr("Trop de tentative, fin de partie ! Entrée pour recommencer.", 2000)
                        }
                        this.setState({ GS_: tmp, guess: "" })
                    }
                }
            }
        }
        else {
            if (this.state.end === false) {
                if (letter === "backspace") {
                    if (guess.length > 0) guess = guess.substring(0, guess.length - 1)
                }
                else {
                    if (guess.length === 5) this.setErr("Entrée pour valider")
                    else {
                        if (alphabet.indexOf(letter) !== -1) {
                            guess = guess + letter
                        }
                    }
                }
                this.setState({ guess: guess })
            }
        }
    }

    setScore() {
        var score = this.state.score, best = this.state.best
        var raw_score = {
            1: 10, 2: 7, 3: 5, 4: 3, 5: 2
        }
        score[0] = score[0] + 1
        score[1] = raw_score[this.state.guesses.length] ? score[1] + raw_score[this.state.guesses.length] : score[1] + 1
        if (score[0] > best[0]) best[0] = JSON.parse(JSON.stringify(score[0]))
        if (score[1] > best[1]) best[1] = JSON.parse(JSON.stringify(score[1]))
        this.setState({ score: score, best: best })
    }

    newword() {
        this.setState({ score: [0, 0], guess: "", guesses: [], word: data[Math.floor(Math.random() * data.length)] })
    }

    render() {
        var G_ = this.state.guess
        var GS_ = JSON.parse(JSON.stringify(this.state.guesses))
        GS_.reverse()
        return (
            <div className='noselect' style={{ margin: "auto", maxWidth: 400 }}>
                {/* <div onClick={(e) => this.setState({ show: !this.state.show })} style={{ padding: 20, borderBottom: "1px solid #ededed" }}>
                    show
                    <span style={{ fontStyle: "italic", color: "#cacaca", paddingLeft: 10 }}>
                        {this.state.show ? this.state.word : null}
                    </span>
                </div> */}
                <div onClick={(e) => this.newword()} style={{ padding: 20, display: "flex", justifyContent: "center" }}>
                    Trouve le mot
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            Best streak: {this.state.best[0]}
                        </div>
                        <div>
                            Actual streak: {this.state.score[0]}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            Best score: {this.state.best[1]}
                        </div>
                        <div>
                            Actual score: {this.state.score[1]}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ padding: 20, display: "flex", position: "relative" }}>
                        <div style={{ position: "absolute", color: "red", left: "50%", transform: "translateX(-50%)", top: "90%", width: "100%", fontSize: 13, textAlign: "center" }}>
                            {this.state.err}
                        </div>
                        <input type="text" tabindex="1" id="inputRef" style={{ position: 'absolute', top: 12, left: 0, width: "100%", height: 84, opacity: 0 }} />
                        {[0, 1, 2, 3, 4].map(e =>
                            <div key={'l' + e} style={{ padding: "18px 18px", marginRight: 8, color: "#000", fontSize: 20, backgroundColor: "#efefef", borderRadius: 3, textTransform: "uppercase" }}>
                                {G_.length - 1 >= e ? G_[e] : "_"}
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
                    <div style={{ padding: 20 }}>
                        {GS_.map((g, gI) =>
                            <div key={'g' + gI} style={{ padding: 5, display: "flex", position: "relative" }}>
                                <div style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: -20, fontSize: 14, color: "#8e8e8e" }}>#{GS_.length - gI}</div>
                                {[0, 1, 2, 3, 4].map(e =>
                                    <div key={gI + 'gl' + e} style={{ padding: "18px 18px", marginRight: 8, color: "#000", fontSize: 20, backgroundColor: g[e][1] === 2 ? "green" : g[e][1] === 1 ? "orange" : "#efefef", borderRadius: 3, textTransform: "uppercase" }}>
                                        {g[e][0]}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div onClick={(e) => this.newword()} style={{ padding: 20, borderTop: "1px solid #ededed", display: "flex", justifyContent: "center", marginTop: 20 }}>
                    <div style={{ margin: "auto", color: "#cacaca", textDecoration: "underline", cursor: "pointer" }}>
                        new game
                    </div>
                </div>
            </div >
        )
    }

}

export default Word;
