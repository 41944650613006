
import React, { Component } from 'react';

class Fight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            m: {},
            m_: {},
            start: false,
            hexa: {
                0: "#48A2E8",
                1: "#D55034"
            },
            fast: 50,
            tick: 0,
            win: null
        }
        this.len = 40
    }

    componentDidMount() {
        this.init()
    }

    init() {
        var m = {}
        var m_ = {}
        for (var x = 0; x < this.len; x++) {
            for (var y = 0; y < this.len; y++) {
                if (x < this.len / 2) m[x + "-" + y] = 0
                else m[x + "-" + y] = 1
                m_[x + "-" + y] = 0
            }
        }
        this.setState({ m: m, m_: m_, win: null, tick: 0 })
        setTimeout(function () {
            this.up()
        }.bind(this), 1000)
    }

    getNbr() {
        var m = this.state.m
        var n = 0
        var l = Object.keys(m).length
        Object.keys(m).forEach(ele => {
            n = m[ele] + n
        })
        var c = this.state.hexa
        return (
            <div style={{ display: 'inline-flex', marginTop: 20 }}>
                <div style={{ display: 'inline-flex', marginRight: 10, fontWeight: l - n > n ? "bold" : "normal" }}>
                    <div>
                        {l - n}
                    </div>
                    <div style={{ height: 8, width: 8, border: "1px solid #fff", backgroundColor: c[0], marginTop: 6, marginLeft: 3 }}></div>
                </div>
                <div style={{ display: 'inline-flex', marginLeft: 10, fontWeight: n > l - n ? "bold" : "normal" }}>
                    <div style={{ height: 8, width: 8, border: "1px solid #fff", backgroundColor: c[1], marginTop: 6, marginRight: 3 }}></div>
                    <div>
                        {n}
                    </div>
                </div>
            </div>
        )
    }

    up() {
        if (this.state.start === true) {
            var m = this.state.m
            var m_ = this.state.m_
            var n = 0
            var l = Object.keys(m).length
            Object.keys(m).forEach(ele => {
                var x = parseInt(ele.split('-')[0])
                var y = parseInt(ele.split('-')[1])
                var test_coord = [
                    (x - 1) + '-' + (y),
                    (x) + '-' + (y - 1),
                    (x + 1) + '-' + (y),
                    (x) + '-' + (y + 1),
                ]
                test_coord.forEach(c => {
                    if (m[c] !== undefined) {
                        if (m[ele] !== m[c]) {
                            if (Math.floor(Math.random() * Math.floor(2)) === 0) {
                                m[c] = Math.floor(Math.random() * Math.floor(2))
                                m_[c] = m_[c] + 1
                            }
                            else {
                                if (m[ele] !== m[c]) {
                                    m[ele] = Math.floor(Math.random() * Math.floor(2))
                                    m_[ele] = m_[ele] + 1
                                }
                            }
                        }
                    }
                })
                n = m[ele] + n
            })
            this.setState({ m: m, m_: m_, tick: this.state.tick + 1 })
            if (n === 0 || n === l) this.setState({ start: false, win: n === l ? 1 : 0 })
            setTimeout(function () {
                this.up()
            }.bind(this), this.state.fast)
        }
    }

    changeN(v) {
        var m = this.state.m
        var m_ = this.state.m_
        console.log(v, m_[v])
        if (m[v] === 0) m[v] = 1
        else m[v] = 0
        this.setState({ m: m })
    }

    changeHexa(index, val) {
        var h = this.state.hexa
        h[index] = val
        this.setState({ h: h })
    }

    render() {
        var m = this.state.m
        // var m_ = this.state.m_
        var c = this.state.hexa
        return (
            <div style={{ minHeight: window.innerHeight, backgroundColor: "#fafafa", paddingBottom: 100 }}>
                <div className="container text-center" style={{ position: "relative" }}>
                    <img alt="quit_onboard" src="/quit_onboard.svg" className="close-btn-work" style={{ left: 30, height: 13, top: 24, position: 'absolute' }} onClick={() => window.location = '/'} />
                    <div className="text-center" style={{ fontSize: 20, paddingTop: 20, fontFamily: "Meri" }}>
                        Pix'Fight
                    </div>
                    <div>
                        <div style={{ marginTop: 30, fontFamily: "Do", fontSize: 16, letterSpacing: "0.4em" }}>
                            Control
                        </div>
                        <div style={{ display: "inline-block", textAlign: "center" }}>
                            {this.state.win !== null ? null :
                                <div style={{ display: "inline-block", cursor: 'pointer', marginTop: 10, marginBottom: 25, marginRight: 10, textDecoration: "underline" }} onClick={() => {
                                    this.setState({ start: !this.state.start });
                                    setTimeout(function () { this.up() }.bind(this), this.state.fast)
                                }}>
                                    {this.state.start === false ? 'Start' : 'Pause'}
                                </div>
                            }
                            <div style={{ display: "inline-block", cursor: 'pointer', marginTop: 10, marginBottom: 25, marginLeft: this.state.win !== null ? 0 : 10, textDecoration: "underline" }} onClick={() => { this.setState({ start: false, win: null, tick: 0 }); setTimeout(function () { this.init() }.bind(this), 100) }}>
                                Reset
                        </div>
                        </div>
                    </div>
                    <div style={{ display: 'inline-block', width: this.len * 8, height: this.len * 8, position: "relative" }}>
                        <div style={{ position: 'absolute', top: -12, right: 0, fontSize: 8, color: "#999" }}>
                            {this.state.tick} ticks
                        </div>
                        {this.state.win !== null ?
                            <div className="center-absolute" style={{ fontFamily: "DoSemiBold", zIndex: 50, fontSize: 16, letterSpacing: "0.4em", display: "inline-flex", marginBottom: 40, width: 'fit-content', whiteSpace: "pre-line", textAlign: "center" }}>
                                Win in {this.state.tick} ticks
                            </div>
                            : null}
                        {Object.keys(m).map((p, pI) =>
                            <div className={"transition-" + this.state.fast} onClick={() => this.state.win !== null ? null : this.changeN(p)} key={p} style={{ height: 8, width: 8, border: "0px solid #fff", backgroundColor: /* m_[p] > 1000 ? "green" : */ c[m[p]], position: "absolute", left: parseInt(p.split('-')[0]) * 8, top: parseInt(p.split('-')[1]) * 8, cursor: "pointer" }}></div>
                        )}
                    </div>
                    <div>
                        {this.getNbr()}
                    </div>
                    <div style={{ marginTop: 30, fontFamily: "Do", fontSize: 16, letterSpacing: "0.4em" }}>
                        Adjust color
                    </div>
                    <div style={{ display: "inline-flex", width: "100%", marginTop: 10 }}>
                        <div style={{ width: "50%", position: "relative" }}>
                            <input placeholder="rgb, hexa, name..." type='text' value={this.state.hexa[0]} onChange={(e) => this.changeHexa(0, e.target.value)} style={{ width: 100, backgroundColor: this.state.hexa[0] + "22", border: 0, borderBottom: "1px solid " + this.state.hexa[0], padding: "6px 10px", float: "right", marginRight: 10 }} />
                        </div>
                        <div style={{ width: "50%", position: "relative" }}>
                            <input placeholder="rgb, hexa, name..." type='text' value={this.state.hexa[1]} onChange={(e) => this.changeHexa(1, e.target.value)} style={{ width: 100, backgroundColor: this.state.hexa[1] + "22", border: 0, borderBottom: "1px solid " + this.state.hexa[1], padding: "6px 10px", float: "left", marginLeft: 10 }} />
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: 30, fontFamily: "Do", fontSize: 16, letterSpacing: "0.4em" }}>
                            Adjust size
                        </div>
                        <input type='number' step={2} value={this.len} min={4} max={100} onChange={(e) => { this.len = e.target.value; this.init() }} style={{ width: 50, margin: "auto", marginTop: 10, backgroundColor: "#55555522", border: 0, borderBottom: "1px solid #555", padding: "6px 5px 6px 10px" }} />
                    </div>
                    <div>
                        <div style={{ marginTop: 30, fontFamily: "Do", fontSize: 16, letterSpacing: "0.4em" }}>
                            Adjust speed
                        </div>
                        <div className="text-center" style={{ display: "inline-block", width: "100%", marginTop: 10 }}>
                            <div onClick={() => this.setState({ fast: 25 })} style={{ display: "inline-block", margin: "auto 20px", textDecoration: "underline", cursor: "pointer" }}>
                                25ms
                            </div>
                            <div onClick={() => this.setState({ fast: 50 })} style={{ display: "inline-block", margin: "auto 20px", textDecoration: "underline", cursor: "pointer" }}>
                                50ms
                            </div>
                            <div onClick={() => this.setState({ fast: 100 })} style={{ display: "inline-block", margin: "auto 20px", textDecoration: "underline", cursor: "pointer" }}>
                                100ms
                            </div>
                            <div onClick={() => this.setState({ fast: 200 })} style={{ display: "inline-block", margin: "auto 20px", textDecoration: "underline", cursor: "pointer" }}>
                                200ms
                            </div>
                            <div onClick={() => this.setState({ fast: 500 })} style={{ display: "inline-block", margin: "auto 20px", textDecoration: "underline", cursor: "pointer" }}>
                                500ms
                            </div>
                            <div onClick={() => this.setState({ fast: 1000 })} style={{ display: "inline-block", margin: "auto 20px", textDecoration: "underline", cursor: "pointer" }}>
                                1s
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Fight;
