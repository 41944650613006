
import React, { Component } from 'react';
import { Hexagon, Text } from 'react-hexgrid';

var img = {
    "mountain": true,
    "low_mountain": true,
    "high_hill": true,
    "hill": true,
    "plain": true,
    "low_plain": true,
}

class WGTile extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        var h = this.props.h
        var hI = this.props.hI
        var arroundSelect = this.props.arroundSelect
        var selected = this.props.selected
        var effect = this.props.effect
        return (
            <Hexagon q={h['coord'][0]} r={h['coord'][1]} s={h['coord'][2]} className={"hover-info-params-action change-hexa-color-" + h["params"]['type']}
                onClick={() => this.props.handleClick(hI, h['coord'])}>
                {img[h["params"]['type']] === true ?
                    <image xlinkHref={`/maps/nature/${h["params"]['type']}.png`} height="12.7" width="13.6" x="-6.9" y="-6.3" />
                    : null}
                {h["params"]['build'] ?
                    <image xlinkHref={`/maps/city/${h["params"]['build'] + '_' + h["params"]['level']}.png`} height="12.7" width="13.6" x="-6.9" y="-6.3" />
                    : null}
                {effect === hI ?
                    <image className='cons_anim' xlinkHref={`/maps/effect/effect.gif`} height="20" width="20" x="-10" y="-10" />
                    : null}
                {h["params"]['special'] === "treasure" ?
                    <Text className="ttt-svg">φ</Text> : null}
                {h["params"]['special'] === "temple" ?
                    <Text className="tttt-svg">Ω</Text> : null}
                {arroundSelect.indexOf(h['coord'][0] + '-' + h['coord'][1]) !== -1 ?
                    <image xlinkHref={`/maps/effect/highlight.png`} height="12.7" width="13.6" x="-6.9" y="-6.3" />
                    : null}
                {selected === hI ?
                    <image xlinkHref={`/maps/effect/select.png`} height="12.7" width="13.6" x="-6.9" y="-6.3" />
                    : null}
            </Hexagon>
        )
    }

}

export default WGTile;
