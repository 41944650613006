
import React, { PureComponent } from 'react';
var $ = require("jquery");

class Ghost extends PureComponent {

    constructor(props) {
        super(props);
        this.img = props.param.img;
        this.id = props.id;
        this.speed = props.param.speed;
        this.day = props.day;
        this.startPos = props.param.startPos;
        this.height = props.height;
        this.width = props.width;

        this.newq = [0, this.startPos]
    }

    componentDidMount() {
        this.animateDiv()
    }

    makeNewPosition() {
        var old = this.newq
        var mw = this.width - 30;
        var maxR = old[1] + 80
        var minR = old[1] - 80
        if (maxR >= mw) maxR = mw
        if (minR <= 30) minR = 30
        var nw = Math.floor(Math.random() * (maxR - minR + 1)) + minR;
        var nh = Math.floor(Math.random() * (22 - 17 + 1)) + 17;
        return [nh, nw];
    }

    animateDiv() {
        this.newq = this.makeNewPosition();
        $("#" + this.id).animate({ bottom: this.newq[0], left: this.newq[1] }, this.speed, "linear", function () {
            this.animateDiv()
        }.bind(this));

    }

    render() {
        return (
            <img alt={this.img} src={this.img} id={this.id} style={{ position: "absolute", height: 25, bottom: 19, left: this.startPos, filter: `brightness(${this.day ? 1 : 2})` }} />
        );
    }
}

export default Ghost
