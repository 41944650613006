
import React, { PureComponent } from 'react';
var $ = require("jquery");

class Person extends PureComponent {

    constructor(props) {
        super(props);
        this.img = props.param.img;
        this.id = props.id;
        this.day = props.day;
        this.speed = props.param.speed;
        this.startPos = props.param.startPos;
        this.height = props.height;
        this.width = props.width;

        this.newq = [0, this.startPos, "scaleX(1)"]
    }

    componentDidMount() {
        this.animateDiv()
    }

    makeNewPosition() {
        var old = this.newq
        var rotate = "scaleX(1)"
        var mw = this.width - 30;
        var maxR = old[1] + 80
        var minR = old[1] - 80
        if (maxR >= mw) maxR = mw
        if (minR <= 30) minR = 30
        var nw = Math.floor(Math.random() * (maxR - minR + 1)) + minR;
        var nh = 19;
        if (nw < old[1]) rotate = "scaleX(-1)"
        return [nh, nw, rotate];
    }

    animateDiv() {
        this.newq = this.makeNewPosition();
        $("#" + this.id).css("transform", this.newq[2]);
        // $("#" + this.id).css("filter", `brightness(${this.day ? 1 : 0.6})`);
        $("#" + this.id).animate({ bottom: this.newq[0], left: this.newq[1] }, this.speed, "linear", function () {
            this.animateDiv()
        }.bind(this));

    }

    render() {
        return (
            <img alt={this.img} src={this.img} id={this.id} style={{ position: "absolute", height: 25, bottom: 17, left: this.startPos, transform: 'scaleX(1)' }} />
        );
    }
}

export default Person
