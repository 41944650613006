

function randMM(min, max) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min);
}

function makeid(len, listExistsId = []) {
    var good_res = false
    while (good_res === false) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        for (var i = 0; i < len; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        var idExists = false
        listExistsId.forEach(ele => {
            if (ele["id"] === result) idExists = true
        })
        good_res = !idExists
        if (good_res === false) console.log('WRONG')
    }
    return result;
}

export { randMM, makeid }
