import React, { Component } from 'react';
import noise from './utils/noise.js'


function invertHex(hex) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}

var defaultColors = {
    AAA: '#e9ecef',
    AA: '#adb5bd',
    A: '#ccc15f',
    B: '#bcb954',
    C: '#a5a850',
    D: '#92a54f',
    E: '#86a049',
    F: '#4d68af',
    G: '#3b559b',
    H: '#293f77',
    I: '#24386b',
    J: '#233668',
    K: '#213466',
    L: '#1d2e5b'
}

var defaultColorsType = {
    "high_mountain": '#e9ecef',
    "mountain": '#adb5bd',
    "low_mountain": '#ccc15f',
    "high_hill": '#bcb954',
    "hill": '#a5a850',
    "plain": '#92a54f',
    "low_plain": '#86a049',
    "beach_coast": '#4d68af',
    "front_coast": '#3b559b',
    "mid_coast": '#293f77',
    "deep_coast": '#24386b',
    "front_ocean": '#233668',
    "mid_ocean": '#213466',
    "deep_ocean": '#1d2e5b'
}

var defaultType = {
    AAA: 'high_mountain',
    AA: 'mountain',
    A: 'low_mountain',
    B: 'high_hill',
    C: 'hill',
    D: 'plain',
    E: 'low_plain',
    F: 'beach_coast',
    G: 'front_coast',
    H: 'mid_coast',
    I: 'deep_coast',
    J: 'front_ocean',
    K: 'mid_ocean',
    L: 'deep_ocean'
}

class WorldGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            area: [],
            panelOpen: false,
            defaultOptions: {
                width: 40,
                height: 60,
                noiseReduction: 8,
                seed: 0,
                size: 0.7,
                elevation: 3.4,
                lakeSize: 3,
                curve: 0.4
            }
        }
        this.index = {}
        this.size = [60, 40]
        this.uniqueSize = 18
    }

    componentDidMount() {
        this.generate()
    }

    generateMap(options) {
        // github crusoe helped
        options = Object.assign({}, this.state.defaultOptions, options)

        var width = options.width
        var height = options.height
        var seed = options.seed
        var size = options.size - 1
        var curve = options.curve * 2
        var elevation = -2 + options.elevation
        var lakeSize = options.lakeSize
        var scale = options.noiseReduction
        var scaleHalf = scale / 2
        var scale2 = scale * 2

        var islandW = width * 0.5
        var islandH = height * 0.5
        var diagonalHalf = Math.sqrt(width * width + height * height)

        noise.seed(seed)

        var data = []

        for (var x = 0; x < width; x++) {
            data[x] = []

            for (var y = 0; y < height; y++) {
                var dist = Math.sqrt(Math.pow((x - width / 2) / islandW, 2) + Math.pow((y - height / 2) / islandH, 2))
                var islandFactor = (dist < diagonalHalf ? Math.cos(dist * Math.PI / 2) + size : -10) * 4 * curve

                var terrainFactor = (noise.simplex2(x / scale, y / scale) + noise.perlin2(x / scaleHalf, y / scaleHalf)) / 3
                var lakeFactor = islandFactor > 0 ? noise.perlin2(x / scale2, y / scale2) * lakeSize * islandFactor : 0

                var n = (islandFactor + terrainFactor + lakeFactor) / 3

                if (n < 0) {
                    data[x][y] = n
                } else {
                    // Land
                    var climateFactor = ((noise.simplex2(x / scale, y / scale) + noise.perlin2(x / scale2, y / scale2)) / 2 + elevation) / 2 + islandFactor / (50 / curve)
                    if (climateFactor < 0) {
                        climateFactor = 0
                    }

                    data[x][y] = climateFactor
                }
            }
        }
        return { data: data, width: width, height: height }
    }

    getColor(n, colors) {
        if (n > 0.985) return colors.AAA
        if (n > 0.95) return colors.AA
        else if (n > 0.9) return colors.A
        else if (n > 0.8) return colors.B
        else if (n > 0.75) return colors.C
        else if (n > 0.65) return colors.D
        else if (n > 0) return colors.E
        else if (n > -0.1) return colors.F
        else if (n > -0.15) return colors.G
        else if (n > -0.2) return colors.H
        else if (n > -0.25) return colors.I
        else if (n > -0.3) return colors.J
        else if (n > -0.35) return colors.K
        else return colors.L
    }

    getType(n, colors) {
        if (n > 0.985) return defaultType.AAA
        if (n > 0.95) return defaultType.AA
        else if (n > 0.9) return defaultType.A
        else if (n > 0.8) return defaultType.B
        else if (n > 0.75) return defaultType.C
        else if (n > 0.65) return defaultType.D
        else if (n > 0) return defaultType.E
        else if (n > -0.1) return defaultType.F
        else if (n > -0.15) return defaultType.G
        else if (n > -0.2) return defaultType.H
        else if (n > -0.25) return defaultType.I
        else if (n > -0.3) return defaultType.J
        else if (n > -0.35) return defaultType.K
        else return defaultType.L
    }

    renderMap(map, customColors) {
        var colorMap = Object.assign({}, defaultColors, customColors)
        var isl = []
        var colors = []
        for (var x = 0; x < map.width; x++) {
            colors[x] = []
            isl[x] = []
            for (var y = 0; y < map.height; y++) {
                var type = this.getType(map.data[x][y], colorMap)
                colors[x][y] = this.getColor(map.data[x][y], colorMap)
                isl[x][y] = {
                    "type": type,
                    "id": x + '-' + y
                }
            }
        }

        return {
            width: map.width,
            height: map.height,
            colors: colors,
            isl: isl
        }
    }

    generate() {
        var map = this.renderMap(this.generateMap({ seed: Date.now() }), {})
        // console.log(map)
        // this.setState({ area: map['colors'] })
        this.setState({ area: map['isl'] })
    }

    updateValue(type, val) {
        var opt = this.state.defaultOptions
        opt[type] = parseFloat(val)
        this.setState({ defaultOptions: opt })
    }

    handleClick(id, x, y, val) {
        var gr = this.state.area
        if (["beach_coast", "front_coast", "mid_coast", "deep_coast", "front_ocean", "mid_ocean", "deep_ocean", "high_mountain"].indexOf(val['type']) !== -1) {
            alert('Cant build here')
        }
        else {
            if (gr[x][y]['build'] === 'house') gr[x][y]['build'] = null
            else gr[x][y]['build'] = "house"
            this.setState({ area: gr })
        }
    }

    render() {
        var d = [
            {
                'name': "size",
                "min": "0.1",
                "max": "1",
                "step": "0.1",
            },
            {
                'name': "noiseReduction",
                "min": "1",
                "max": "50",
                "step": "1"
            },
            {
                'name': "elevation",
                "min": "0",
                "max": "5",
                "step": "0.1"
            },
            {
                'name': "curve",
                "min": "0.1",
                "max": "5",
                "step": "0.1"
            },
            {
                'name': "lakeSize",
                "min": "0",
                "max": "10",
                "step": "0.1"
            }
        ]
        var _A = this.state.area
        var margT = (this.state.totalHeight - 100 - this.size[1] * this.uniqueSize) / 2
        return (
            <div style={{ minHeight: this.state.totalHeight, backgroundColor: "#1d2e5b", fontFamily: "Marc", textAlign: "center", overflowX: "auto" }}>
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                    {Object.keys(defaultColors).map((c, cI) =>
                        <div key={"color-" + cI} style={{ backgroundColor: defaultColors[c], width: 80, textTransform: "capitalize", fontSize: 12, fontFamily: "Do", color: "#" + invertHex(defaultColors[c].replace('#', '')) }}>
                            {defaultType[c].replace('_', " ")}
                        </div>
                    )}
                </div>
                <div style={{ minWidth: this.size[0] * this.uniqueSize, minHeight: this.size[1] * this.uniqueSize, maxWidth: this.size[0] * this.uniqueSize, maxHeight: this.size[1] * this.uniqueSize, display: "inline-block", textAlign: "center", marginTop: margT }}>
                    {_A.map((x, xI) =>
                        x.map((y, yI) =>
                            <div className="one-tile-grind" onClick={() => this.handleClick(xI + '-' + yI, xI, yI, y)} key={'grid-tile-' + xI + '-' + yI} id={'grid-tile-' + xI + '-' + yI}
                                style={{ height: this.uniqueSize, width: this.uniqueSize, backgroundColor: defaultColorsType[y['type']] }}>
                                {y['build'] === "house" ? <img src="/house.png" alt="/house.png" style={{ height: "200%", top: "22%", left: "18%" }} className="center-absolute" /> : null}
                            </div>
                        ))}
                </div>
                <div className={this.state.panelOpen ? "panel-control-open transition-300 scroll-small-black" : "panel-control-close transition-300 scroll-small-black"} style={{}}>
                    <div className="btn-panel" onClick={() => this.setState({ panelOpen: !this.state.panelOpen })}>
                        <img src="/arrow_w.png" alt="/arrow_w.png" className="transition-100" style={{ height: 16, marginRight: 5, marginTop: -2, transform: this.state.panelOpen ? "rotate(90deg)" : "rotate(-90deg)" }} />
                        {this.state.panelOpen ? "Close " : "Open "} Panel
                    </div>
                    <div onClick={() => this.generate()} className="btn-generate transition-300">
                        RE-GENERATE
                    </div >
                    <div>
                        {d.map((ii, inI) =>
                            <div key={inI}>
                                <p style={{ textTransform: "uppercase" }}>{ii['name']}: <span id="options-size">{this.state.defaultOptions[ii['name']]}</span></p>
                                <input type="range" min={ii['min']} max={ii['max']} step={ii['step']} value={this.state.defaultOptions[ii['name']]} onChange={(e) => this.updateValue(ii['name'], e.target.value)} />
                            </div>
                        )}
                    </div>
                </div>
            </div >
        )
    }

}

export default WorldGame;
