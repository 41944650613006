
import React, { Component } from 'react';
import { compressToBase64, decompressFromBase64 } from 'lz-string'
import Ghost from './Component/Ghost'
import Person from './Component/Person'
// var $ = require("jquery");

var col = ["007f5f", "2b9348", "55a630", "80b918", "aacc00", "bfd200", "d4d700", "dddf00", "eeef20", "ffff3f"]

class Civ extends Component {

    constructor(props) {
        super(props);
        // this.areaW = 500
        this.areaH = 180
        this.state = {
            arr: [],
            time: 1,
            openSettings: false,
            savePop: false,
            copyDone: false,
            key: "",
            party: {
                "research": 0,
                "exploration": 0,
                "cultural": 0,
                "money": 0,
            }
        }
        this.arr_bg = []
    }

    componentDidMount() {
        var nbr_bg = window.innerWidth / 150
        for (var y = 0; y < nbr_bg; y++) {
            this.arr_bg.push("/civ/bg_civ/bg_" + Math.floor(Math.random() * 7) + ".png")
        }
        this.setState({ r: 4 })
        this.load()
    }

    refreshBg() {
        var nbr_bg = window.innerWidth / 150
        this.arr_bg = []
        for (var y = 0; y < nbr_bg; y++) {
            this.arr_bg.push("/civ/bg_civ/bg_" + Math.floor(Math.random() * 7) + ".png")
        }
        this.setState({ r: 4 })
    }

    randColor() {
        return "#" + col[Math.floor(Math.random() * (col.length - 0 + 1)) + 0]
    }

    add() {
        var arr = this.state.arr
        var n = {
            "type": "ghost",
            "img": "/civ/p/p_" + Math.floor(Math.random() * 2) + ".png",
            "color": this.randColor(),
            "speed": Math.floor(Math.random() * (2100 - 1400 + 1)) + 1400,
            "startPos": Math.floor(Math.random() * ((window.innerWidth - 200) - 100)) + 100,
        }
        arr.push(n)
        this.setState({ arr: arr })
    }

    addPerson() {
        // https://ezgif.com/maker
        var arr = this.state.arr
        var n = {
            "type": "person",
            "img": "/civ/p/u/u_2.gif",
            "color": this.randColor(),
            "speed": Math.floor(Math.random() * (2100 - 1400 + 1)) + 1400,
            "startPos": Math.floor(Math.random() * ((window.innerWidth - 200) - 100)) + 100,
        }
        arr.push(n)
        this.setState({ arr: arr })
    }

    adds(nbr) {
        for (var d = 0; d < nbr; d++) this.add()
    }

    reset() {
        this.setState({
            arr: [], key: "", time: 1,
            party: { "research": 0, "exploration": 0, "cultural": 0, "money": 0 }
        })
        localStorage.removeItem('save_random_civ')
    }

    load() {
        var bc = localStorage.getItem('save_random_civ')
        try {
            var bcP = JSON.parse(decompressFromBase64(bc))
            this.setState({ arr: bcP['arr'], party: bcP['party'] })
        } catch {
            console.log("Wrong save")
        }
    }

    save() {
        this.setState({ savePop: true })
        var save = {
            "arr": this.state.arr,
            "party": this.state.party
        }
        var saveText = JSON.stringify(save)
        var encodeTxt = compressToBase64(saveText)
        localStorage.setItem("save_random_civ", encodeTxt)
        this.setState({ key: encodeTxt })
        setTimeout(function () {
            this.setState({ savePop: false })
        }.bind(this), 1000)
    }

    loadCopy() {
        var text = document.getElementById('key-rec-load').value
        try {
            var bcP = JSON.parse(decompressFromBase64(text))
            this.setState({
                arr: bcP['arr'],
                party: bcP['party']
            })
            document.getElementById('key-rec-load').value = ""
        } catch {
            alert("Wrong save")
        }
    }

    copyCode() {
        var copyText = document.getElementById('key-rec')
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.setState({ copyDone: true })
        setTimeout(function () {
            this.setState({ copyDone: false })
        }.bind(this), 1000)
    }

    render() {
        return (
            <div className="text-center" style={{ width: "100%", border: "0px solid", position: "relative" }}>
                <div id="container-w" className="noselect transition-300" style={{ position: 'relative', width: "100%", height: this.areaH }}>
                    {this.arr_bg.map((bg, bgI) =>
                        <img alt="bg" className="transition-300" key={bgI + bg} src={bg}
                            style={{ left: 150 * bgI, bottom: 0, height: "100%", position: "absolute", filter: `brightness(${this.state.time})` }} />
                    )}
                    {this.state.arr.map((ele, eleI) =>
                        ele.type === 'ghost' ?
                            <Ghost key={"pers-" + eleI} id={"pers-" + eleI} param={ele} height={0} width={window.innerWidth} day={this.state.time === 1} />
                            :
                            <Person key={"pers-" + eleI} id={"pers-" + eleI} param={ele} height={0} width={window.innerWidth} day={this.state.time === 1} />
                    )}
                </div>
                <div className={"panel-action transition-300 special_bg_NOP" + this.state.time.toString().replace('.', '-')} style={{ height: window.innerHeight - this.areaH }}>
                    <div className="container" style={{ textAlign: "left" }}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div style={{ fontWeight: 600, marginBottom: 10 }}>
                                    Stats
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 no-padding">
                                    <div>
                                        Population: {this.state.arr.length}
                                    </div>
                                    <div>
                                        Money: {this.state.party['money']}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div style={{ fontWeight: 600, marginBottom: 10 }}>
                                    Actions
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding" style={{ marginLeft: 0 }}>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="btn-siv transition-300 noselect" onClick={() => this.add()} style={{ cursor: 'pointer' }}>
                                            Add pop
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="btn-siv transition-100 noselect" onClick={() => this.adds(10)} style={{ cursor: 'pointer' }}>
                                            Add pop x10
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="btn-siv transition-300 noselect" onClick={() => this.addPerson()} style={{ cursor: 'pointer' }}>
                                            Add person
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: 100 }}>
                                <div style={{ fontWeight: 600, marginBottom: 10, cursor: "pointer" }} onClick={() => this.setState({ openSettings: !this.state.openSettings })}>
                                    Options {this.state.openSettings ? "-" : "+"}
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row no-padding transition-300"
                                    style={this.state.openSettings ? { marginLeft: 0 } : { display: "none" }}>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                        <div className="btn-opt transition-100 noselect" onClick={() => this.save()} style={{ cursor: 'pointer' }}>
                                            Save
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                        {this.state.key ?
                                            <input readOnly id="key-rec" className="input-param" style={this.state.savePop || this.state.copyDone ? { color: "#2a9d8f" } : {}} value={this.state.copyDone ? "Copied !" : this.state.savePop ? "Saved !" : this.state.key} />
                                            : null}
                                        {this.state.key ?
                                            <div style={{ position: "absolute", right: 15, top: "50%", transform: "translate(0, -50%)", width: 25, height: 25, zIndex: 4, cursor: 'pointer', /* backgroundColor: "#ddd" */ }} onClick={() => this.copyCode()}>
                                                <img alt="valid" className="center-absolute" src={this.state.copyDone ? '/civ/valid_tick.png' : '/civ/copy_icn.png'} style={{ width: this.state.copyDone ? "130%" : "100%", marginTop: 4 }} />
                                            </div>
                                            : null}
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                        <div className="btn-opt transition-100 noselect" onClick={() => this.load()} style={{ cursor: 'pointer' }}>
                                            Load
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                        <input id="key-rec-load" className="input-param" placeholder="Enter your saved key here" />
                                        <div style={{ position: "absolute", right: 15, top: "50%", transform: "translate(0, -50%)", width: 25, height: 25, zIndex: 4, cursor: 'pointer', /* backgroundColor: "#ddd"  */ }} onClick={() => this.loadCopy()}>
                                            <img alt="arrow" src='/civ/arrow_right.png' style={{ width: "80%", marginTop: 6 }} />
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                        <div className="btn-opt transition-100 noselect" onClick={() => this.reset()} style={{ cursor: 'pointer', backgroundColor: "#e63946" }}>
                                            Reset
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                                        <div className="btn-opt transition-100 noselect" onClick={() => this.refreshBg()} style={{ cursor: 'pointer' }}>
                                            Refresh BG
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" style={{ marginTop: 100 }}>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', top: 4, left: 4, display: 'inline-flex', cursor: "pointer", zIndex: 1000 }}
                            onClick={() => this.setState({ time: this.state.time === 1 ? 0.4 : 1 })}>
                            <img alt="sunmoon" style={{ width: "150%", filter: `brightness(${this.state.time === 1 ? 1 : 0.8})` }} src={this.state.time === 1 ? '/civ/sun.png' : '/civ/moon.png'} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Civ;
