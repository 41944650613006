
import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// var $ = require("jquery");

var normal = ["-", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
var special = {
    "1": ["-", 0, 1, 2, 3, 4, 5],
    "2": ["-", 0, 2, 4, 6, 8, 10],
    "3": ["-", 0, 3, 6, 9, 12, 15],
    "4": ["-", 0, 4, 8, 12, 16, 20],
    "5": ["-", 0, 5, 10, 15, 20, 25],
    "6": ["-", 0, 6, 12, 18, 24, 30],
    "full": ["-", 0, 30],
    "little": ["-", 0, 20],
    "large": ["-", 0, 60],
    "yahtzee": ["-", 0, 85]
}

var trad = {
    'en': {
        "1": "Aces",
        "2": "Twos",
        "3": "Threes",
        "4": "Fours",
        "5": "Fives",
        "6": "Sixes",
        'brelan': "3 of a kind",
        'carre': "4 of a kind",
        'full': "Full house",
        "little": "Small straight",
        "large": "Large straight",
        "chance": "Chance",
        "yahtzee": "Yahtzee"
    }
}

class Yam extends Component {

    constructor(props) {
        super(props)
        this.state = {
            players: [],
            showPlayer: true,
            currentSlide: 0,
            dice: false,
            rollNbr: 0,
            dices: [
                { 'locked': false, "value": 1 },
                { 'locked': false, "value": 1 },
                { 'locked': false, "value": 1 },
                { 'locked': false, "value": 1 },
                { 'locked': false, "value": 1 },
            ]
        }
    }

    componentDidMount() {
    }

    next = () => {
        this.setState((state) => ({ currentSlide: state.currentSlide + 1 }));
    };

    prev = () => {
        this.setState((state) => ({ currentSlide: state.currentSlide - 1 }));
    };

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;
        if (currentSlide !== index) this.setState({ currentSlide: index });
    };

    getNewPoss() {
        var allPoss = ["1", "2", "3", "4", "5", "6", "brelan", "carre", "full", "little", "large", "chance", "yahtzee"]
        var poss = []
        var score = {}
        allPoss.forEach((ele, eleI) => {
            poss.push({
                'name': ele,
                'value': "",
                "value_possible": Object.keys(special).indexOf(ele) === -1 ? normal : special[ele],
                "type": ["1", "2", "3", "4", "5", "6"].indexOf(ele) === -1 ? "combinaison" : "number"
            })
            score[eleI] = "-"
        })
        return { 'list': poss, 'score': score }
    }

    randomName() {
        var names = ["Michael", "Dwight", "Jim", "Pam", "Ryan", "Andy", "Robert", "Stanley", "Kevin", "Meredith", "Oscar", "Phyllis", "Roy", "Jan", "Kelly", "Angela", "Toby", "Creed", "Darryl", "Erin", "Gabe", "Holly", "Nellie", "Clark", "Pete", "David", "Rolando", "Todd", "Nate", "Ed", "Hunter"]
        return names[Math.floor(Math.random() * names.length)]
    }

    addPoss() {
        var name = document.getElementById('newname').value
        if (name === "") name = this.randomName()
        var players = this.state.players
        var posAl = this.getNewPoss()
        players.push({
            'name': name,
            'possibility': posAl['list'],
            "score": posAl['score']
        })
        document.getElementById('newname').value = ""
        this.setState({ players: players })
    }

    delPlayer(index) {
        var players = this.state.players
        players.splice(index, 1)
        this.setState({ players: players, currentSlide: 0 })
    }

    getTotal(indexPlayer) {
        var players = this.state.players
        var score = 0
        var scoreNumber = 0
        Object.keys(players[indexPlayer]['score']).forEach((ele, eleI) => {
            if (players[indexPlayer]['score'][ele] !== "-") {
                if (eleI < 6) scoreNumber = scoreNumber + players[indexPlayer]['score'][ele]
                score = score + players[indexPlayer]['score'][ele]
            }
        })
        var isBonus = 0
        if (scoreNumber >= 63) {
            score = score + 35
            isBonus = 35
        }
        return { "score": score, "bonus": isBonus }
    }

    validScore(val, indexU, indexN) {
        var players = this.state.players
        players[indexU]['score'][indexN] = val === "-" ? "-" : parseInt(val)
        this.setState({ players: players })
        setTimeout(function () {
            document.getElementById("btn-next").click()
        })
    }

    sortPlayers() {
        var sor = []
        var players = this.state.players
        players.forEach((ele, eleI) => {
            var score = this.getTotal(eleI)
            sor.push({
                'name': ele['name'],
                'score': score['score']
            })
        })
        function compare(a, b) {
            const bandA = a.score
            const bandB = b.score
            let comparison = 0;
            if (bandA > bandB) comparison = -1;
            else if (bandA < bandB) comparison = 1;
            return comparison;
        }
        sor.sort(compare)
        sor.forEach((ele, eleI) => {
            sor[eleI]['diff'] = 0
            if (eleI > 0) {
                sor[eleI]['diff'] = sor[eleI - 1]['score'] - sor[eleI]['score']
            }
        })
        return sor
    }

    lockDice(index) {
        var dices = this.state.dices
        dices[index]['locked'] = !dices[index]['locked']
        this.setState({ dices: dices })
    }

    rollDice() {
        var dices = this.state.dices
        var newRN = this.state.rollNbr
        if (newRN === 0) newRN = 1
        else if (newRN === 1) newRN = 2
        else if (newRN === 2) newRN = 0
        dices.forEach((ele, eleI) => {
            if (newRN === 0) dices[eleI]['locked'] = false
            if (ele['locked'] === false) dices[eleI]['value'] = Math.floor(Math.random() * 6) + 1
        })
        this.setState({ dices: dices, rollNbr: newRN })
    }

    render() {
        return (
            <div className="container" style={{ maxWidth: 400, position: "relative" }}>
                <img alt="quit_onboard" src="/quit_onboard.svg" className="close-btn-work" style={{ left: 30, height: 13, top: 9, position: 'absolute' }} onClick={() => window.location = '/'} />
                <div style={{ position: 'absolute', cursor: "pointer", right: 20, top: 2, zIndex: 5, display: "flex" }}>
                    <div style={{ height: 25, width: 25, opacity: this.state.showPlayer ? 1 : 0.4 }}>
                        <img alt={"/player.png"} src={"/player.png"} style={{ height: 25 }} onClick={() => this.setState({ showPlayer: !this.state.showPlayer })} />
                    </div>
                    <div style={{ height: 25, width: 25, opacity: this.state.dice ? 1 : 0.4 }}>
                        <img alt={"/dice.png"} src={"/dice.png"} style={{ height: 25, marginTop: 1 }} onClick={() => this.setState({ dice: !this.state.dice })} />
                    </div>
                </div>
                <div className="transition-100" style={{ fontSize: 20, letterSpacing: "0.06em", fontFamily: "SPSemi", marginTop: 10, paddingBottom: 15, marginBottom: this.state.showPlayer ? 15 : 0, borderBottom: this.state.showPlayer ? "2px solid" : "0", textAlign: "center", fontWeight: 600 }}>
                    Yahtzee
                </div>
                <div className="transition-100" style={{ display: "flex", justifyContent: "space-between", paddingBottom: this.state.showPlayer ? 15 : 0, marginBottom: 0, borderBottom: this.state.dice ? "2px solid" : "2px solid transparent", overflow: "hidden", height: this.state.showPlayer ? 63 : 0 }}>
                    <input type="text" placeholder={"Player name"} id="newname" style={{ paddingLeft: 10, border: 0, borderBottom: "1px solid #ccc", height: 36, marginTop: 2 }} />
                    <div style={{ cursor: "pointer", backgroundColor: "#000", color: "#fff", padding: "6px 15px", height: 32, marginTop: 7 }} onClick={() => this.addPoss()}>
                        Add player
                    </div>
                </div>
                <div className="transition-100" style={{ paddingBottom: this.state.dice ? 30 : 0, marginBottom: 20, borderBottom: "2px solid", overflow: "hidden", height: this.state.dice ? 97 : 0 }}>
                    <div onClick={() => this.rollDice()} style={{ cursor: 'pointer', textDecoration: "underline", marginBottom: 10, marginTop: 10 }}>
                        {this.state.rollNbr === 0 ? "First roll" : this.state.rollNbr === 1 ? <span>Second Roll<span style={{ paddingLeft: 12, color: "#cdcdcd", textDecoration: "underline", fontSize: 11 }}>(Click on the dices you want to keep)</span></span> : "Restart rolling the dices"}
                    </div>
                    <div style={{ display: 'inline-flex', width: "100%" }}>
                        {this.state.rollNbr === 0 ? null : this.state.dices.map((val, valI) =>
                            <div key={"dice_" + valI} style={{ width: "20%", height: 50, position: "relative" }}>
                                <img alt="dice" src={val['locked'] === false ? "/dice/" + val['value'] + ".png" : "/dice/" + val['value'] + "_l.png"} style={{ height: 35, cursor: 'pointer' }} onClick={() => this.lockDice(valI)} className="center-absolute" />
                            </div>
                        )}
                    </div>
                </div>
                <div className='change-car'>
                    <Carousel
                        onChange={(e) => this.setState({ currentSlide: e })}
                        selectedItem={this.state.currentSlide}
                        infiniteLoop={true}
                        showIndicators={false}
                        emulateTouch={true}
                        swipeable={this.state.players.length > 1 ? true : false}
                        showThumbs={false}
                    >
                        {this.state.players.map((ele, eleI) =>
                            <div key={"player_" + eleI} className='card-player'>
                                <div style={{ textAlign: "left", fontWeight: "bold", marginBottom: 10 }}>
                                    {ele['name']} <span onClick={() => this.delPlayer(eleI)}>x</span>
                                </div>
                                <div>
                                    <div style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: 13, paddingBottom: 4, borderBottom: "1px solid", marginBottom: 10 }}>
                                        Number
                                    </div>
                                    <div style={{ display: 'inline-flex', width: "100%" }}>
                                        {[1, 2].map(lit =>
                                            <div key={'lsit_lit_' + lit} style={{ width: "calc(50% - 15px)", position: "relative", marginLeft: lit === 1 ? 0 : 30 }}>
                                                {ele['possibility'].map((num, numI) =>
                                                    (lit === 1 && numI >= 3) || (lit === 2 && numI < 3) ? null :
                                                        num['type'] === "combinaison" ? null :
                                                            <div key={'number_list_' + eleI + numI} style={{ display: 'flex', justifyContent: "space-between", borderBottom: "1px solid #cecece", paddingBottom: 4, marginBottom: 5 }}>
                                                                <div style={{ fontSize: 14 }}>
                                                                    {num['name']} - {Object.keys(trad['en']).indexOf(num['name']) !== -1 ? trad['en'][num['name']] : num['name']}
                                                                </div>
                                                                <select className="select-yam" value={ele['score'][numI]} id={"select_" + eleI.toString() + numI} onChange={(e) => this.validScore(e.target.value, eleI, numI)}>
                                                                    {num['value_possible'].map((opt, optI) =>
                                                                        <option key={'opt_num_' + eleI + optI} value={opt}>
                                                                            {opt}
                                                                        </option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: 13, paddingBottom: 4, marginTop: 20, borderBottom: "1px solid", marginBottom: 10 }}>
                                        Combinaison
                                    </div>
                                    {ele['possibility'].map((num, numI) =>
                                        num['type'] === "combinaison" ?
                                            <div key={'combo_list_' + numI + eleI} style={{ display: 'flex', justifyContent: "space-between", borderBottom: "1px solid #cecece", paddingBottom: 4, marginBottom: 5 }}>
                                                <div style={{ fontSize: 14 }}>
                                                    {Object.keys(trad['en']).indexOf(num['name']) !== -1 ? trad['en'][num['name']] : num['name']}
                                                </div>
                                                <select className="select-yam" value={ele['score'][numI]} id={"select_" + eleI.toString() + numI} onChange={(e) => this.validScore(e.target.value, eleI, numI)}>
                                                    {num['value_possible'].map((opt, optI) =>
                                                        <option key={'opt_combo_' + eleI + optI}>
                                                            {opt}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                            : null
                                    )}
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div style={{ textTransform: "uppercase", fontWeight: "bold", fontSize: 13, paddingBottom: 4, marginTop: 20, borderBottom: "1px solid", marginBottom: 10 }}>
                                        Total
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            Number Bonus{" (>63pts)"}:  + {this.getTotal(eleI)["bonus"]}
                                        </div>
                                        <div style={{ fontWeight: "bold" }}>
                                            {this.getTotal(eleI)["score"]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </Carousel>
                    <button id="btn-next" style={{ position: "fixed", top: -50, left: -50, height: 0, width: 0, overflow: "hidden", zIndex: -1 }}
                        onClick={() => this.next()}></button>
                    <button style={{ position: "fixed", top: -50, left: -50, height: 0, width: 0, overflow: "hidden", zIndex: -1 }}
                        onClick={() => this.prev()}></button>
                    {/*<div style={{ cursor: 'pointer', fontWeight: "bold", borderBottom: "2px solid", paddingBottom: 10, marginBottom: 15, marginTop: 30 }} onClick={() => this.setState({ dice: !this.state.dice })}>
                        {this.state.dice ? "Hide dices >" : "You don't have any dices? Click here !"}
                    </div>*/}
                    {this.state.players.length > 1 ?
                        <div>
                            <div style={{ fontWeight: "bold", borderBottom: "2px solid", paddingBottom: 10, marginBottom: 15, marginTop: 30 }}>
                                Rank
                            </div>
                            <div>
                                {this.sortPlayers().map((ele, eleI) =>
                                    <div key={'list_score_' + eleI} style={{ display: 'flex', justifyContent: "space-between", borderBottom: "1px solid #cecece", marginBottom: 5, paddingBottom: 4 }}>
                                        <div style={{ display: 'inline-flex' }}>
                                            <img alt="medals-" src={eleI < 3 ? "/medals/" + (eleI + 1) + ".png" : "/medals/last.png"} style={{ height: 22 }} />
                                            <div style={{ paddingTop: 0, marginLeft: 4 }}>
                                                {ele['name']}
                                            </div>
                                        </div>
                                        <div style={{ display: "inline-flex" }}>
                                            {eleI === 0 ? null : <div style={{ color: "#bbb" }}> + {ele["diff"]}</div>}
                                            <div style={{ width: 40, textAlign: "right" }}>{ele['score']}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

export default Yam
