
function fetchRoom(ids) {
    var ret_rooms = []
    ids.forEach(id => {
        ret_rooms.push(rooms[id])
    });
    return ret_rooms
}

function fetchItem(ids) {
    var ret_items = []
    ids.forEach(id => {
        ret_items.push(items[id])
    });
    return ret_items
}
/*
function fetchHint(ids) {
    var ret_hints = []
    ids.forEach(id => {
        ret_hints.push(hints[id])
    });
    return ret_hints
}
*/
function doAction(id, tmp_rooms, tmp_inventory, tmp_hints, indexRoom) {
    var action = actions[id]
    var descContent = []
    if (action !== undefined) {
        action.forEach(a => {
            // create desc total
            if (a['desc'] !== "") descContent.push(a['desc'])
            // action
            if (a['type'] === 'add_r') {
                tmp_rooms = tmp_rooms.concat(fetchRoom(a.ids))
            }
            if (a['type'] === 'add_i') {
                tmp_inventory = tmp_inventory.concat(fetchItem(a.ids))
            }
            if (a['type'] === 'remove_a') {
                a['ids'].forEach(action_id => {
                    var toDel = null
                    tmp_rooms[indexRoom]['actions'].forEach((a_i, a_iIndex) => {
                        if (a_i['_id'] === action_id) toDel = a_iIndex
                    })
                    if (toDel !== null) {
                        delete tmp_rooms[indexRoom]['actions'][toDel]
                    }
                })
            }
            // if room delete change indexRoom
        })
    }
    return { 'rooms': tmp_rooms, 'inventory': tmp_inventory, 'hints': tmp_hints, 'indexRoom': indexRoom, 'descAction': descContent }
}

/*
    add_r: add a room
    update_r: update a room
    remove_r: remove a room

    add_i: add a item
    update_i: update a item
    remove_i: remove a item

    add_h: add a hint
    update_h: update a hint
    remove_h: remove a hint

    add_a: add a action
    update_a: update a action
    remove_a: remove a action
*/

var actions = {
    'action_1': [
        {
            'type': "add_r",
            'ids': ["room_2"],
            'desc': "You only see one door, no window, nothing."
        },
        {
            'type': "remove_a",
            'ids': ["action_1"],
            'desc': ""
        },

    ],
    'action_2': [
        {
            'type': "add_i",
            'ids': ["item_1"],
            'desc': "You found an object."
        },
        {
            'type': "remove_a",
            'ids': ["action_2"],
            'desc': ""
        },

    ],
    'action_3': [
        {
            'type': "add_i",
            'ids': ["item_2"],
            'desc': "You found an object."
        },
        {
            'type': "remove_a",
            'ids': ["action_3"],
            'desc': ""
        },

    ],
    'action_4': [
        {
            'type': "add_r",
            'ids': ["room_3"],
            'desc': "You see a door, but it seems locked."
        },
        {
            'type': "remove_a",
            'ids': ["action_4"],
            'desc': ""
        },

    ]
}

var items = {
    'item_1': {
        '_id': 'item_1',
        'name': 'Flashlight'

    },
    'item_2': {
        '_id': "item_2",
        'name': 'Weird key'
    }
}

// var hints = {
//     'hint_1': {
//         '_id': 'hint_1',
//         'name': 'Hint one',

//     },
// }

var rooms = {
    'room_1': {
        'name': 'Room 1',
        '_id': "room_1",
        "active_needed": [],
        'active': true,
        "thing_needed": [],
        'show_action': true,
        "actual_desc": "A basic room, nothing really special to see.",
        'actions': [
            {
                '_id': "action_1",
                'title': "Look arround you",
                'desc': "You may find something..."
            },
            {
                '_id': "action_2",
                'title': "Inspect the room",
                'desc': "Maybe there is more to find arround here..."
            }
        ]
    },
    'room_2': {
        'name': 'Room 2',
        '_id': "room_2",
        "active_needed": [],
        'active': true,
        "thing_needed": ['item_1'],
        'show_action': false,
        "actual_desc": "It's too dark here...",
        'actions': [
            {
                '_id': "action_4",
                'title': "Look arround you",
                'desc': "You may find something..."
            },
            {
                '_id': "action_3",
                'title': "Inspect the room",
                'desc': "Maybe there is more to find arround here..."
            }
        ]
    },
    'room_3': {
        'name': 'Room 3',
        '_id': "room_3",
        "active_needed": ['item_2'],
        'active': false,
        "thing_needed": [],
        'show_action': true,
        "actual_desc": "Unlocked",
        'actions': [
        ]
    },
    'room_4': {
        'name': 'Room 4',
        '_id': "room_4",
        "active_needed": ['item_3'],
        'active': false,
        "thing_needed": [],
        'show_action': true,
        "actual_desc": "Locked",
        'actions': [
        ]
    },
}


export { fetchRoom, doAction }
