
import React, { Component } from 'react';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class Workout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalHeight: window.innerHeight,
            totalWidth: window.innerWidth,
            workoutStart: false,
            workoutEnd: false,
            bgColor: "#000",
            fontColor: "#fff",
            timeoutOn: false,
            title: false,
            nextStep: false,
            openPanel: false,
            workout_steps: [],
            workout_1: [
                {
                    'type': 'Push-up',
                    'timestamp': 30,
                    'img_w': "/work/push_w.png",
                    "img": "/work/push.png",
                    'id': 0,
                    'select': true
                },
                {
                    'type': 'Abdominal Crunch',
                    'timestamp': 60,
                    'img_w': "/work/abdo_w.png",
                    "img": "/work/abdo.png",
                    'id': 1,
                    'select': true
                },
                {
                    'type': 'Lunges',
                    'timestamp': 60,
                    'img_w': "/work/lunge_w.png",
                    "img": "/work/lunge.png",
                    'id': 2,
                    'select': true
                },
                {
                    'type': 'High knees running in place',
                    'timestamp': 60,
                    'img_w': "/work/highknee_w.png",
                    "img": "/work/highknee.png",
                    'id': 3,
                    'select': true
                },
                {
                    'type': 'Squat',
                    'timestamp': 60,
                    'img_w': "/work/squat_w.png",
                    "img": "/work/squat.png",
                    'id': 4,
                    'select': true
                },
                {
                    'type': 'Wall sit',
                    'timestamp': 40,
                    'img_w': "/work/chair_w.png",
                    "img": "/work/chair.png",
                    'id': 5,
                    'select': false
                },
                {
                    'type': 'Plank',
                    'timestamp': 40,
                    'img_w': "/work/plank_w.png",
                    "img": "/work/plank.png",
                    'id': 6,
                    'select': false
                },
                {
                    'type': 'Jumping Jack',
                    'timestamp': 60,
                    'img_w': "/work/jjack_w.png",
                    "img": "/work/jjack.png",
                    'id': 7,
                    'select': false
                },
                {
                    'type': 'Push up and rotate',
                    'timestamp': 30,
                    'img_w': "/work/rotate_w.png",
                    "img": "/work/rotate.png",
                    'id': 8,
                    'select': false
                }
            ],
            workoutIndex: 0
        }
    }

    componentDidMount() {
        var arrShuffle = this.shuffle(JSON.parse(JSON.stringify(this.state.workout_1)))
        this.setState({ workout_steps: arrShuffle })
        document.body.style.overflow = "hidden"
        this.setState({ totalHeight: window.innerHeight, totalWidth: window.innerWidth })
        window.addEventListener('resize', function (event) {
            this.setState({ totalHeight: window.innerHeight, totalWidth: window.innerWidth })
        }.bind(this));
    }

    componentWillUnmount() {
        document.body.style.overflow = "auto"
    }

    changeSelect(id) {
        var word = this.state.workout_1
        word.forEach(ele => {
            if (ele['id'] === id) ele['select'] = !ele['select']
        })
        this.setState({ workout_1: word })
        var arrShuffle = this.shuffle(JSON.parse(JSON.stringify(this.state.workout_1)))
        this.setState({ workout_steps: arrShuffle })
    }

    Countdown(elem, seconds) {
        var that = {};
        that.elem = document.getElementById('time');
        that.seconds = seconds;
        that.totalTime = seconds * 100;
        that.usedTime = 0;
        that.startTime = +new Date();
        that.timer = null;

        that.count = function () {
            that.usedTime = Math.floor((+new Date() - that.startTime) / 10);

            var tt = that.totalTime - that.usedTime;
            if (tt <= 0) {
                document.getElementById('time').innerHTML = '00.00';
                clearInterval(that.timer);
            } else {
                var mi = Math.floor(tt / (60 * 100));
                var ss = Math.floor((tt - mi * 60 * 100) / 100);
                var ms = tt - Math.floor(tt / 100) * 100;

                document.getElementById('time').innerHTML = that.fillZero(ss) + "." + that.fillZero(ms);
            }
        };

        that.init = function () {
            if (that.timer) {
                clearInterval(that.timer);
                document.getElementById('time').innerHTML = '00.00';
                that.totalTime = seconds * 100;
                that.usedTime = 0;
                that.startTime = +new Date();
                that.timer = null;
            }
        };

        that.start = function () {
            if (!that.timer) {
                that.timer = setInterval(that.count, 10);
            }
        };

        that.stop = function () {
            console.log('usedTime = ' + this.countdown.usedTime);
            if (that.timer) clearInterval(that.timer);
        };

        that.fillZero = function (num) {
            return num < 10 ? '0' + num : num;
        };
        return that;
    }

    shuffle(arrayOld) {
        var array = []
        JSON.parse(JSON.stringify(arrayOld)).forEach(ele => {
            if (ele['select']) array.push(ele)
        })
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    async oneTimer(time) {
        var span = document.getElementById('time');
        this.countdown = new this.Countdown(span, time);
        this.countdown.init();
        this.countdown.start(time);
        await timeout(time * 1000)
    }

    async handleOne() {
        this.setState({ workoutStart: true, bgColor: "#fff", fontColor: "#000", timeoutOn: true, title: true, nextStep: true })
        await this.oneTimer(10)
        this.setState({ bgColor: "#000", fontColor: "#fff", nextStep: false })
        await this.oneTimer(this.state.workout_steps[this.state.workoutIndex]['timestamp'])
    }

    async handleWorkout() {
        this.setState({ r: 1 })
        for (var y = 0; y < this.state.workout_steps.length; y++) {
            this.setState({ workoutIndex: y })
            this.setState({ r: 1 })
            await this.handleOne()
        }
        this.setState({ workoutStart: false, workoutEnd: true, bgColor: "#fff", fontColor: "#000" })
    }

    getExercice() {
        var sec = 0
        var nbr = 0
        this.state.workout_steps.forEach(ele => {
            nbr = nbr + 1
            sec = sec + ele['timestamp']
        })
        return { 'sec': sec, 'nbr': nbr }
    }

    render() {
        return (
            <div style={{ width: this.state.totalWidth, height: this.state.totalHeight, backgroundColor: this.state.bgColor, position: "relative" }} className='transition-300'>
                <div onClick={() => {
                    if (this.state.openPanel) {
                        // event.stopPropagation()
                        this.setState({ openPanel: !this.state.openPanel })
                    }
                }}
                    className={this.state.openPanel ? 'back-open transition-300' : 'back-close transition-300'} style={{ width: this.state.totalWidth, height: this.state.totalHeight }}>
                </div>
                <div className={this.state.openPanel ? "panel-choose-open transition-300" : "panel-choose transition-300"} style={{ height: this.state.totalHeight }}>
                    <div className="center-absolute" style={{ display: 'block', width: "100%", top: "45%", paddingLeft: 25 }}>
                        <div className='title-choose'>
                            Select exercices you want to do
                        </div>
                        {this.state.workout_1.map((w, wI) =>
                            <div key={'list-work-' + wI} style={{ display: 'inline-flex', width: "100%", marginTop: 10, cursor: "pointer", fontSize: 13 }} onClick={() => this.changeSelect(w.id)}>
                                <div className={w.select ? "transition-100 box-work-select" : "transition-100 box-work-unselect"}></div>
                                <img alt={w.img} src={w.img} style={{ height: 30, marginLeft: 6, marginTop: -5 }} />
                                <div style={{ marginLeft: 6 }}>
                                    {w.type} <span style={{ fontSize: 10, color: "#aaa" }}>({w.timestamp}sec)</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='close-btn-choose' onClick={() => this.setState({ openPanel: !this.state.openPanel })}>
                        DONE
                    </div>
                </div>
                <div>
                    <img alt="/quit_onboard.svg" src="/quit_onboard.svg" className="close-btn-work" onClick={() => { this.state.openPanel ? this.setState({ openPanel: !this.state.openPanel }) : this.state.workoutStart ? window.location = '/' : window.location = '/' }} />
                    {this.state.nextStep ?
                        <div className="start-workout transition-300" style={{ color: "#000", position: "fixed", textAlign: "center", width: "85%", top: 50, left: "50%", transform: "translateX(-50%)", cursor: "unset" }}>
                            PREPARE TO DO
                        </div>
                        : null}
                    {this.state.workoutEnd ? null :
                        this.state.workoutStart ?
                            <img alt='img-isnextstep' src={this.state.nextStep ? this.state.workout_steps[this.state.workoutIndex]['img'] : this.state.workout_steps[this.state.workoutIndex]['img_w']}
                                style={{ position: 'fixed', bottom: 70, left: "50%", transform: "translateX(-50%)" }} />
                            : null}
                    <div onClick={() => this.state.workoutStart ? null : this.handleWorkout()} className="center-absolute start-workout transition-300"
                        style={{ top: "45%", color: this.state.fontColor, textAlign: "center", width: "90%", cursor: !this.state.workoutStart ? "pointer" : "unset" }}>
                        {this.state.workoutEnd ?
                            <div onClick={() => window.location = '/'} style={{}}>
                                WORKOUT FINISHED<br /><br /><br />
                                BACK TO HOME PAGE
	                            <div className="pyro">
                                    <div className="before"></div>
                                    <div className="after"></div>
                                </div>
                            </div>
                            : this.state.title ?
                                <div style={{ textTransform: 'uppercase', marginBottom: 20 }}>
                                    {this.state.workout_steps[this.state.workoutIndex]['type']}
                                </div>
                                : null}
                        {this.state.nextStep ? <div style={{ textTransform: 'uppercase', marginBottom: 20 }}>IN</div> : null}
                        {this.state.workoutEnd ? null : this.state.workoutStart ?
                            <span style={{ color: this.state.nextStep ? "#000" : "#fff" }} id="time" className="time">00.00</span>
                            : <span className="hover-start">START</span>}
                        {this.state.workoutEnd ? null : this.state.workoutStart ? null :
                            <div className="desc-workout">
                                Content:<br />
                                - {this.getExercice()['nbr']} exercices, {this.getExercice()['sec']} sec total.<br />
                                With 10 sec rest between. Good luck, you bitch.
                            </div>
                        }
                    </div>
                    {this.state.workoutEnd ? null : this.state.workoutStart ? null :
                        <div className="btn-desc-choose" onClick={() => this.setState({ openPanel: !this.state.openPanel })}>
                            Choose my exercices
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Workout
