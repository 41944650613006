
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component } from 'react';
import Simu from './Simu';
import Simu2 from './Simu2';
import Creations from './Creations';
import Workout from './Workout';
import Quests from './Quests';
import Civ from './Civ/Civ';
import Yam from "./Yam";
import Files from "./Files";
import File from "./File";
import EditorFile from "./EditorFile";
import Fight from "./Fight";
import Coco from "./Coco";
import Procedural from "./Procedural";
import WorldGame from "./WorldGame";
import WorldGame2 from "./WorldGame2";
import Tiles from "./Tiles";
import EventCard from "./EventCard";
import Cities from "./Cities/Cities";
import Word from "./Word";

const NoMatchPage = () => {
    window.location = '/'
    return null;
};

class App extends Component {

    render() {
        return (
            <div style={{}}>
                <Router>
                    <Switch>
                        <Route path="/" exact render={(e) => {
                            return <Creations />
                        }} />
                        <Route path="/cities" exact render={(e) => {
                            return <Cities />
                        }} />
                        <Route path="/simu" exact render={(e) => {
                            return <Simu />
                        }} />
                        <Route path="/simu2" exact render={(e) => {
                            return <Simu2 />
                        }} />
                        <Route path="/workout" exact render={(e) => {
                            return <Workout />
                        }} />
                        <Route path="/tiles" exact render={(e) => {
                            return <Tiles />
                        }} />
                        <Route path="/eventcard" exact render={(e) => {
                            return <EventCard />
                        }} />
                        <Route path="/civ" exact render={(e) => {
                            return <Civ />
                        }} />
                        <Route path="/fight" exact render={(e) => {
                            return <Fight />
                        }} />
                        <Route path="/word" exact render={(e) => {
                            return <Word />
                        }} />
                        <Route path="/coco" exact render={(e) => {
                            return <Coco />
                        }} />
                        <Route path="/random_world_generator" exact render={(e) => {
                            return <WorldGame />
                        }} />
                        <Route path="/worldgame" exact render={(e) => {
                            return <WorldGame2 />
                        }} />
                        <Route path="/maps" exact render={(e) => {
                            return <Procedural />
                        }} />
                        <Route path="/files" exact render={(e) => {
                            return <Files />
                        }} />
                        <Route path="/files/:id" exact render={(e) => {
                            return <File id={e.match.params['id']} />
                        }} />
                        <Route path="/yatz" exact render={(e) => {
                            return <Yam />
                        }} />
                        <Route path="/quests" exact render={(e) => {
                            return <Quests />
                        }} />
                        <Route path="/editor" exact render={(e) => {
                            return <EditorFile />
                        }} />
                        <Route path="/editor/:id" exact render={(e) => {
                            return <EditorFile id={e.match.params['id']} />
                        }} />
                        <Route component={NoMatchPage} />
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default App
