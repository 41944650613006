
import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// var $ = require("jquery");

class File extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            currentSlide: 0
        }
    }

    componentDidMount() {
        var files_01 = require('./utils/files/files_01.json');
        var file = null
        files_01.forEach(ele => {
            if (ele['id'] === this.props.id) file = ele
        })
        this.setState({ file: file })
    }

    next = () => {
        this.setState((state) => ({ currentSlide: state.currentSlide + 1 }));
    };

    prev = () => {
        this.setState((state) => ({ currentSlide: state.currentSlide - 1 }));
    };

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;
        if (currentSlide !== index) this.setState({ currentSlide: index });
    };

    render() {
        var f = this.state.file
        return (
            <div className="container no-padding-mobile">
                {f === null ? null :
                    <div style={{ fontFamily: "Do", fontSize: 18, letterSpacing: "0.02em" }}>
                        <div className="banner-file" style={{ backgroundImage: `url(${f['image']})` }}>
                            <div style={{ position: 'absolute', top: 10, left: 15 }}>
                                <a href={"/files"} className="no-underline-hover" style={{ color: "#000" }}>
                                    <img alt="arrow_right" src='/civ/arrow_right.png' style={{ height: 18, width: "auto", transform: "rotate(180deg)" }} />
                                </a>
                            </div>
                        </div>
                        <div className="text-center title-file" style={{ fontSize: 20, paddingTop: 20 }}>
                            {f['name']}
                        </div>
                        <div className='change-carN change-car-file' style={{ paddingBottom: 40 }}>
                            <Carousel
                                // key={f['content'][this.state.currentSlide]}
                                onChange={(e) => this.setState({ currentSlide: e })}
                                selectedItem={this.state.currentSlide}
                                infiniteLoop={false}
                                showIndicators={false}
                                emulateTouch={false}
                                swipeable={false}
                                showArrows={false}
                                showThumbs={false}
                            // dynamicHeight={true}
                            >
                                {f['content'].map((c, cI) =>
                                    <div className="div-content" key={'para_' + cI}>
                                        <div dangerouslySetInnerHTML={{ __html: c }}>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 0, marginTop: 25, textDecoration: "underline", fontSize: 18 }}>
                                            <div className="transition-300" onClick={() => this.prev()} style={{ opacity: this.state.currentSlide === 0 ? 0 : 1, cursor: "pointer", display: "inline-flex" }}>
                                                <img alt="arrow_right" src='/civ/arrow_right.png' style={{ height: 16, width: "auto", transform: "rotate(180deg)", marginTop: 6 }} />
                                                Prev paragraph
                                            </div>
                                            {this.state.currentSlide === f['content'].length - 1 ?
                                                <a href={"/files"} className="no-underline-hover" style={{ color: "#000" }}>
                                                    Back to the files
                                                </a>
                                                :
                                                <div className="transition-300" onClick={() => this.next()} style={{ cursor: "pointer", display: "inline-flex" }}>
                                                    Next paragraph
                                                    <img alt="arrow_right" src='/civ/arrow_right.png' style={{ height: 16, width: "auto", marginTop: 6 }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}

                            </Carousel>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default File;
